angular.module('dashboardApp')
.directive('eloSocialFace', function() {
  return {
    // <elo-social-face social-account="jice_lavocat"></elo-social-face>
    restrict: 'EA',
    scope: {
      account: '='
    },
    templateUrl: 'shared/eloSocialFace/socialView.html',
    controller: function($scope){
        var social_network_name;
        if ($scope.account.name[0] == 'F') {
            social_network_name = "Facebook";
        }
        else if ($scope.account.name[0] == 'T') {
            social_network_name = "Twitter";
        }
        else if ($scope.account.name[0] == 'L') {
            social_network_name = "Linkedin";
        }
        $scope.account.img_title = $scope.account.display_name + " (" + social_network_name + ")";
    },
    // controllerAs: 'vm'
  };
});

