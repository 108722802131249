'use strict';

/**
 * @ngdoc function
 * @name dashboardApp.controller:MainCtrl
 * @description
 * # MainCtrl
 * Controller of the dashboardApp
 */
angular.module('dashboardApp')
  .controller('UpgradeCtrl', function ($scope, ENV, localStorageService, $window, Restangular) {

    $scope.priceIndividualMonthly = 19;
    $scope.priceIndividualYearly = 16;
    $scope.priceProMonthly = 49;
    $scope.priceProYearly = 42;


    var userId = localStorageService.get('user_elokenz_userid');
    var user = localStorageService.get('user');
    console.log("Klapp user :", user.account.is_klapp_user);
    // The base URL for all articles;
    var baseAuthor = Restangular.one('users', userId);

    $scope.duration = 0; // 0 is for yearly, 1 is for monthly

    $scope.switchButton = function(){
      $scope.duration = 1;
    };


    // Used when user has already CC info
    if (user.account.is_klapp_user) {
      $scope.sessionUrl = 'https://klapp.io';
    } else {
      baseAuthor.all('chargebee_portal_session').get([]).then(function(response) {
        $scope.sessionUrl = response.url;
        console.log('Session url ', $scope.sessionUrl);
      });
    }

    $scope.submitForm = function(planName) {
        // Used when user has no CC info yet
        // If Partner, redirect to site
        if (user.account.is_klapp_user) {
          $window.location.href = 'https://klapp.io';
        } else {
          // Otherwise, update via API
          var dataSent = {"plan_name": planName};
          console.log(dataSent);
          baseAuthor.all("chargebee").post(dataSent).then(function(response) {
            $window.location.href = response.url;
          });
        }
      };
})


.controller('UpgradeProcessCtrl', function ($scope, localStorageService, $location, Restangular, $rootScope) {


    var userId = localStorageService.get('user_elokenz_userid');
    // The base URL for all articles;
    var baseAuthor = Restangular.one("users",userId);

    var cid = $location.search().cid;
    var sid = $location.search().sid;
    var status = $location.search().status;

    if (cid !== userId){
      $scope.error = "Not the correct user id ... is there a problem somewhere ?";
    }


    var submitForm = function(sid) {
        var dataSent = {'subscription_id' : sid};
        baseAuthor.all('chargebee_process').post(dataSent).then(function(response) {
          $scope.new_plan = response.new_plan;
          $rootScope.user = response.user;
          $scope.user = response.user;
        }, function(response){
            $scope.error = "Could not process Papi"
        }); // End get articles);
      };

    if (["in_trial, active"].indexOf(status) !== -1){
      submitForm(sid);
    }

});
