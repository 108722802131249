'use strict';

angular.module('dashboardApp')
  .controller('RepostMessageEditCtrl', function ($scope, $rootScope, growl, $filter, Restangular, $analytics, $uibModalInstance, $interval, $location, localStorageService, Utils, messageService, repostService,  spinnerService, socialAccounts, activeAccounts, listedImages, newMessage, isEditing, repost, updatedMessage, $log, $q) {


    // Variables
    var userId = localStorageService.get('user_elokenz_userid');
    var baseAuthor = Restangular.one('users',userId);
    $scope.socialAccounts = socialAccounts;
    $scope.activeAccounts = activeAccounts;
    $scope.listedImages = listedImages;
    $scope.repost = repost;
    var usingImageInMessage = false;
    $scope.images = [];
    $scope.isEditing = (isEditing === undefined) ? false : isEditing;
    $scope.availableMessageLength = 250;
    $scope.maxLength = 250;
    $scope.newMessage = (newMessage === undefined) ? "" : newMessage;
    $scope.messageLength = 250;
    $scope.maxImages = 1;
    $scope.sameMessages = true;
    $scope.updatedMessage = (updatedMessage === undefined) ? "" : updatedMessage;

    // Init :
    // Get  socialAccounts
    Utils.init().then(function () {
      $scope.socialAccounts = Utils.getData().socialAccounts;
      $scope.activeAccounts = Utils.getData().activeAccounts;
    }, function (error) {
      // promise rejected, could log the error with: console.log('error', error);
      console.log('error' , error);
    });

    // Methods

    // Update remaning char count
    $scope.$watch('newMessage', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        if ($scope.newMessage===undefined || $scope.newMessage === null){
          $scope.availableMessageLength = 250;
          $scope.maxLength = 250;
        } else {
            $scope.availableMessageLength = 250 - $scope.newMessage.length;
            $scope.maxLength = 250;
        }
      }
    });


    // Update social account active
    $scope.changeActiveSocialAccount = function (account){
      Utils.changeActiveSocialAccount(account).then(function (){
        $scope.activeAccounts = Utils.getData().activeAccounts;
        $log.debug('change_active_social_account : ', $scope.activeAccounts);
      });
    };

    // Image management

    function addImageToDjango(url) {
      // Later we should save images via Django
      if ($scope.images.length < $scope.maxImages){
        $scope.images.push(url);
      } else{
        $scope.images.splice(0,1,url);
      }
        messageService.addSuggestedImage(url, true);
        $scope.listedImages = messageService.getData().listedImages;
        usingImageInMessage = true;
    }

    // Uploading Images
    $scope.uploadImage = function () {
      uploadcare.openDialog(null, {
        publicKey: "9813ad12b4e36a38d775",
        imageShrink: "640x480",
        crop: "disabled",
        doNotStore: false,
        previewStep: false,
        imagesOnly: true
      }).progress(function(tabName) {
        spinnerService.show('spinnerImageUpload');
      }).fail(function(result) {
        spinnerService.hide('spinnerImageUpload');
        $scope.$apply();
      }).done(function (file) {
        file.promise().done(function (fileInfo) {
          var imageTmpUrl = fileInfo.cdnUrl;
          addImageToDjango(imageTmpUrl);
          spinnerService.hide('spinnerImageUpload');
          $scope.$apply();
        });
      });
    };

    $scope.clearImages = function () {
      $scope.images = [];
      usingImageInMessage = false;
      for (var i = $scope.listedImages.length - 1; i >= 0; i--) {
        $scope.listedImages[i].inUse = false;
      }
    };

    $scope.enableSuggestedImage = function(imageSuggested){
      for (var i = $scope.listedImages.length - 1; i >= 0; i--) {
        $scope.listedImages[i].inUse = false;
      }
      imageSuggested.inUse = true;
      addImageToDjango(imageSuggested.src);
    };

    // Post/Edit Messages

    // When edition is done, we reset variables
    $scope.cancelEdition = function () {
      $scope.newMessage = '';
      $scope.clearImages();
      $scope.isEditing = false;
      $scope.updatedMessage = null;
      $scope.iAmDoneEditing = true;
    };

    $scope.isMessageEnabled = function (message) {
      return messageService.isMessageEnabled(message);
    };

    // Refresh queue
    function refreshQueue() {
      var activeAccountID = Utils.getActiveSocialAccountId();
      // Calling getQueue will refresh the cache of repostService
      repostService.getQueue(activeAccountID);
      repostService.getPots(activeAccountID);
      repostService.sendRepostRefeshEvent();
    }

    // Delete single Message
    $scope.deleteMessage = function (message, viaUpdate) {
      viaUpdate = (viaUpdate === undefined) ? false : viaUpdate;
      if (viaUpdate) {
        // Track in analytics
        $analytics.eventTrack('Deleted message', {  targetObject: 'repost'});
      }

      // remove all associated messages
      for (var i = message.social_accounts.length - 1; i >= 0; i--) {
        var msgId = message.social_accounts[i].messageId;
        if (msgId !== undefined) {
          $scope.repost.one('messages', msgId).remove().then(function () {
            $scope.enabledMessageCount = $filter('filter')($scope.repost.messages, function (d) {return $scope.isMessageEnabled(d)}).length;
            refreshQueue();
          });
        };
      };
      // remove the current message from scope
      $scope.repost.messages.splice(  $scope.repost.messages.indexOf(message), 1 );
    };

    // Post (new) Message
    $scope.postNewMessage = function (message, isUpdating, updatedMessage) {
      $scope.showSpinnerPostMessage = true;
      $scope.iAmDoneEditing = true;

      // Get active social accounts first
      var activeAccounts = $scope.activeAccounts;
      $log.info('postNewMessage : ', activeAccounts);

      if (angular.isUndefined(activeAccounts)) {
        growl.error('You need to select at least one social account associated to this message', {ttl: 5000});
        return;
      }

      if ($scope.availableMessageLength < 0) {
        growl.error('You have too many characters. Please shorten your message', {ttl: 5000});
        return;
      }

      if (angular.isUndefined(message)) {
        growl.error('Hey, you need to type at least a message', {ttl: 5000});
        return;
      }

      if ((activeAccounts.length) < 1) {
        growl.error('You need to select at least one social account associated to this message', {ttl: 5000});
        return;
      }

      var urlImage = [];
      urlImage[0] = null;
      urlImage[1] = null;
      urlImage[2] = null;
      urlImage[3] = null;
      if ($scope.images.length !== 0) {
        for (var i = $scope.images.length - 1; i >= 0; i--) {
          urlImage[i] = $scope.images[i];
        };
      }

      // Add {{url}} at the end if it is not present
      if (message.indexOf("{{url}}") == -1) {
        message = message + ' {{url}}';
      }

      // If we are updating a post, then we should erase the original one
      if (isUpdating) {
        $analytics.eventTrack('Edited message', {  targetObject: 'repost'});
        console.log("jice : ", updatedMessage);
        $scope.deleteMessage(updatedMessage, true);
        $scope.isEditing = false;
      };

      // Post message to first social network
      // We get the associated id, and then create alternative versions with the other social networks
      $scope.repost.all('messages').post({
        'content': message,
        'enabled': true,
        media1: urlImage[0],
        media2: urlImage[1],
        media3: urlImage[2],
        media4: urlImage[3],
        social_account: activeAccounts[0]
      }).then(function (postedMessage) {
        postedMessage.media = [];
        postedMessage.media1 === null ? null : postedMessage.media.push(postedMessage.media1);
        postedMessage.media2 === null ? null : postedMessage.media.push(postedMessage.media2);
        postedMessage.media3 === null ? null : postedMessage.media.push(postedMessage.media3);
        postedMessage.media4 === null ? null : postedMessage.media.push(postedMessage.media4);

        var similarToIndex = postedMessage.id;

        $analytics.eventTrack('Added Message', {targetObject: 'repost'});

        // Save the action for onboarding - Twitter synced
        baseAuthor.one('actions').patch({
          'enabled_first_repost_message': true
        });

        try {
          if (!$rootScope.user.actions.enabled_first_repost_message){
            $rootScope.user.actions.enabled_first_repost_message = true;
            $rootScope.user.actions.overall_progress += 25; // FIXME : this value depends on the number of onboarding actions available
          }
        }
        catch(err) {
        $log.error(err.message);
        }

        // We add the main social account to the posted message
        postedMessage.socialAccounts = [];

        // We add all social accounts
        for (var i = $scope.socialAccounts.length - 1; i >= 0; i--) {
          var tmpAccount = angular.copy($scope.socialAccounts[i]);
          tmpAccount.enabledOnMessage = undefined;
          // If account is used, we enable it directly for the user to see it

          if (activeAccounts.indexOf(tmpAccount.id) !== -1) {
            tmpAccount.enabledOnMessage = true;
          }

          postedMessage.socialAccounts.unshift(tmpAccount);
        }

        var postedMessageMainAccount = $filter('filter')(postedMessage.socialAccounts, function (d) {return d.id === activeAccounts[0];})[0];
        postedMessageMainAccount.enabledOnMessage = true;
        postedMessageMainAccount.messageId = postedMessage.id; // we keep this for further updates

        $scope.cancelEdition();
        $scope.showSpinnerPostMessage = false;


        // Posting Alternates
        // If only one social network, we return
        if (activeAccounts.length === 1) {
          $uibModalInstance.dismiss('cancel');
          return;
        }

        // Else, we iterate over the remaining social networks and create alternates
        // Making use of promises (https://stackoverflow.com/a/41823845/2550237)
        var promises = [];
        for (var j = activeAccounts.length - 1; j >= 1; j--) {
          var messageData = {
            'content': message,
            'enabled': true,
            media1: urlImage[0],
            media2: urlImage[1],
            media3: urlImage[2],
            media4: urlImage[3],
            social_account: activeAccounts[j],
            similar_to: similarToIndex
          };
          promises.push($scope.repost.all('messages').post(messageData));
        }
        $q.all(promises).then(function (){
          $log.info('All similar messages posted');
          $uibModalInstance.dismiss('cancel');
          return;
        }, function (response){
            if (response.non_field_errors !== 'undefined') {
              growl.error('The same message already exists. They have to be unique. Please change your text', {ttl: 5000});
              $scope.showSpinnerPostMessage = false;
              $uibModalInstance.dismiss('cancel');
            } else {
              console.log("Other error");
              $uibModalInstance.dismiss('cancel');
            }
          });


      }, function (response){
        if (response.non_field_errors !== "undefined") {
          growl.error("The same message already exists. They have to be unique. Please change your text", {ttl: 5000});
          $scope.showSpinnerPostMessage = false;
          $uibModalInstance.dismiss('cancel');
        } else {
          console.log("Other error");
          $uibModalInstance.dismiss('cancel');
        }
      });

    };


    // Modal Management
    $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
    };



  })
;
