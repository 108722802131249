var lazyModule = angular.module('lazyModule', []);
lazyModule.factory('lazyLoad', ['Restangular', function (Restangular) {

    return function (obj) {
        if (typeof obj !== "undefined") {
            obj._resultmeta.scrollBusy = true; // stop fetching data while haven't fetched more material
            if (obj._resultmeta.next != '') {
                var $nextUrl = obj._resultmeta.next;
                var $oldMentions = obj;
                var base_api = Restangular.all(""); // hack for Restangular to call allUrl
                Restangular.allUrl(base_api, $nextUrl).getList().then(function (result) {
                    var count = result.length;
                    for (var i = 0; i < count; i++) {
                        obj.push(result[i]);
                        //this.items.push(items[i].data);
                    }
                    obj._resultmeta = result._resultmeta;
                    //$scope._resultmeta.scrollBusy = false; // we can fetch again next time
                }, function (response){
                    console.log(response);
                });
            }
        }
    };
}]);






