angular.module('dashboardApp')
.directive('messageView', function(angularGridInstance) {
  var myDirective = {
        templateUrl: 'components/repost/partials/message_view.html',
        restrict: 'EA',
        link: function (scope, elem, attrs) {
          scope.items = JSON.parse(attrs.items || "null");
          scope.selected = attrs.selected;

          scope.selectItem = function(item) {
            scope.selected = item;
          };
        },
        compile: function(element, attributes){

             return {
                 post: function(scope, element, attributes, controller, transcludeFn){
                    if (angularGridInstance.messagesGrid) {
                        angularGridInstance.messagesGrid.refresh();
                    }
                 }
             }
         },
    };
    return myDirective;
});


