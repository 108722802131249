'use strict';

angular.module('dashboardApp')
  .controller('ModalInstanceCtrl', function ($scope,Restangular, $analytics, $uibModalInstance, $interval, $location, localStorageService, articles, growl, $uibModal, $timeout, repostService, spinnerService, onboardingService) {
    var user_id = localStorageService.get('user_elokenz_userid');
    // The base URL for all articles;
    var baseAuthor = Restangular.one('users',user_id);
    $scope.spinnerPostArticle = false;

    // Get articles
    baseAuthor.all('articles').getList().then(function (articles) {
      $scope.articles = articles;
      spinnerService.hide('spinnerModalRepostLibrary');
    }, function (response) {
      console.log(response);
    });

    // Posting single url
    $scope.postArticle = function (url) {
      $scope.spinnerPostArticle = true;
      var article = {'url' : url};
      var correctUrl = repostService.checkUrl(url);
      if (correctUrl){
        // Add the article to Elokenz (it's not associated to the user yet)
        Restangular.all('pages').post(article).then(function (postedArticle) {
          $analytics.eventTrack('Added Article');
          var i = $scope.articles.length;
          var articleAbsent = true;
          while (i-- && articleAbsent) {
            if ($scope.articles[i].id === postedArticle.id) {articleAbsent = false;}
          }
          if (articleAbsent) {
            // temporarily add the article, even if the author is not yet associated to it
            $scope.articles.push(postedArticle); // Might contain one duplicate
            $scope.url = null;
            // Associate the user and the article
            // Just requires an empty POST to the /users/[userid]/articles/[articleid]/tags
            Restangular.one('users',user_id).one('articles', postedArticle.id).all('tags').post({}).then(function(result){
                $scope.spinnerPostArticle = false;
            }, function (response){
              $scope.spinnerPostArticle = false;
            });

            // If the article is new, let's loop to get the info when they're available
            if (postedArticle.http_status !== 200) {
              $scope.fetchingArticle = true;
              $scope.newArticleFetching(postedArticle);
            }
          } else {
            $scope.createRepost(postedArticle);
          }
        }, function (response){
              $scope.spinnerPostArticle = false;
            });
      }
    };

    var stop;
    $scope.newArticleFetching = function (article) {
      // Don't start a new fetch if we are already fetching
      $scope.tooLong = false;
      // every 3 seconds, 10 times max
      var interval = 3000;
      var repetitions = 10 ;
      if ( angular.isDefined(stop) ) {
        return;
      }
      stop = $interval(function () {
        Restangular.one("users",user_id).one("articles",article.id).get().then(function (newArticle) {
          if (newArticle.http_status === 200) {
            $scope.articles[$scope.articles.indexOf(article)] = newArticle;
            $scope.articles[$scope.articles.indexOf(newArticle)].tags = newArticle.tags_list;
            // Create the repost and redirecting to repost page
            $scope.createRepost(newArticle);

            $scope.stopNewArticleFetching();
          }
        });
      }, interval, repetitions);

      // When the interval is over, let's show an error message
      $timeout(function () {
        $scope.tooLong = true;
        $scope.fetchingArticle = false;
        $scope.spinnerPostArticle = false;
      }, interval * repetitions);
    };

    $scope.stopNewArticleFetching = function() {
      if (angular.isDefined(stop)) {
        $interval.cancel(stop);
        stop = undefined;
      }
    };

    $scope.$on('$destroy', function () {
      // Make sure that the interval is destroyed too
      $scope.stopNewArticleFetching();
    });

    $scope.articles = articles;
    $scope.selected = {
      article: $scope.articles[0]
    };

    $scope.createRepost = function (article) {
      baseAuthor.all('pots').post({
        'article': article.id
      }).then(function (repost) {
        $analytics.eventTrack('Added Repost');
        $uibModalInstance.close($scope.selected.item);
        $scope.fetchingArticle = false;
        $scope.spinnerPostArticle = false;
        onboardingService.addSuccessStep('newRepost'); // Add a new successful step to onboarding if necessary
        $location.path("/repost/"+repost.id);
      }, function (response){
        console.log(response);
        if (response.non_field_errors != "undefined") {
          growl.error("The same Repost already exists", {ttl: 5000});
        } else {
          console.log("Other error");
        };
      });
    };

    $scope.ok = function () {
    $uibModalInstance.close($scope.selected.item);
    };

    $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
    };

    // Menu
    $scope.tab = 1;
    $scope.isSet = function (tabId) {
      return $scope.tab === tabId;
    };
    $scope.setTab = function (tabId) {
      $scope.tab = tabId;
    };

    // Opening Modal
    $scope.warningLibraryFull = function (size) {

      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'components/articles/modal.html',
        controller: 'ModalInstanceCtrlArticles',
        size: size
      });
    };
  })
;
