/**
 * @ngdoc function
 * @name dashboardApp.controller:UploadCSVController
 * @description
 * # uploadCSVCtrl it handles the upload form for csv files to be parsed.
 */
angular.module('dashboardApp')

    .controller('UploadCSVCtrl', ['$scope', 'CSVReader', 'CSVParser', 'growl', 'Restangular', 'localStorageService', 'Utils', '$route', '$log', function ($scope, CSVReader, CSVParser, growl, Restangular, localStorageService, Utils, $route, $log) {
        'use strict';

        // Private variable which holds the current parsed csv data
        var dataPost, uniqueUrlCount;
        var baseUser;


        $scope.getFile = function () {
            // Is it a .csv mister user?
            $log.debug("$scope.file : ", $scope.file);
            if (/.+(\.csv)$/i.test($scope.file.name) !== true ) {
            // var validMimes = ['text/csv', 'application/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/x-vnd.oasis.opendocument.spreadsheet', 'application/vnd.msexcel', 'text/comma-separated-values', 'application/excel', 'text/anytext'];
            // var currentMime = $scope.file.type.toLowerCase();
            // if (/.+(\.csv)$/i.test($scope.file.name) !== true || (validMimes.indexOf(currentMime) == -1))  {
                growl.warning('You need to send a .csv file. Be sure it\'s properly formated');
                $log.error("The CSV file is not properly recognized.");
                $log.error("$scope.file : ", $scope.file);
                return;
            }
            $scope.progress = 0;
            CSVReader.readAsText($scope.file, $scope)
            .then(function (result) {
                var parsedCSV = CSVParser.parse(result);
                parsedCSV.shift();

                var resultArray = CSVParser.formatForPost(parsedCSV);
                dataPost = resultArray[0];
                uniqueUrlCount = resultArray[1];
                $scope.uniqueUrlCount = uniqueUrlCount;
                if (dataPost.length > 300) {
                    growl.warning('You can\'t upload more than 300 rows at once.');
                    return;
                }
                $scope.messagesCount = dataPost.length;

                growl.success('Upload successfull.', {ttl: 2000});

                var userId = localStorageService.get('user_elokenz_userid');
                baseUser = Restangular.one('users', userId);

                Utils.getSocialAccounts(baseUser).then(function(result) {
                    $scope.social_accounts = result;
                    $scope.showAccounts = true;
                });
            });
        };

        $scope.$on('fileProgress', function (e, progress) {
            $scope.progress = 100 * progress.loaded / progress.total;
        });

        $scope.sendApiBulkMessages = function (idSocialAccount) {
            if (!dataPost) {
                return;
            }

            // We define the endpoint here
            var baseSocialAccount = baseUser.one('social_accounts', idSocialAccount);

            baseSocialAccount.all('bulk_messages').post(dataPost)
            .then(function (response) {
                $scope.uploadSuccess = true;
                $log.debug('Bulk upload success', response);
            }, function (error) {
                growl.warning('An error occured. Please try again.');
                $log.error(error);
            });

        };

        $scope.switchActiveAccount = function (SocialAccount) {
            // Switch the "selected" class to the correct social account
            var idx = $scope.social_accounts.indexOf(SocialAccount);
            if (idx === -1) {
                return;
            }

            // Unselect all
            for (var i = 0; i < $scope.social_accounts.length; i++) {
                $scope.social_accounts[i].selected = false;
            }

            $scope.social_accounts[idx].selected = true;
            $scope.selectedAccountId = $scope.social_accounts[idx].id;
        };

        $scope.reloadRoute = function() {
           $route.reload();
        };

    }]);
