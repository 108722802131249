'use strict';

/**
 * @ngdoc function
 * @name dashboardApp.controller:LoginCtrl
 * @description
 * # LoginCtrl
 * Controller of the dashboardApp
 */
angular.module('dashboardApp')
  .controller('LoginCtrl', function ($scope, Restangular, localStorageService, $rootScope, $location, ENV, $analytics, $log, spinnerService, loginService ) {
    $scope.access = false;

    Restangular.one("users", "me").get().then(function(result){
        $scope.access = true;
        $scope.user = result
    }, function(response){
        $scope.access = false;
        $log.error("Couldn't get info about the current user : ", response);
    }); // End get author


    // Login to Elokenz OAuth
    $scope.login = {
      username: undefined,
      password: undefined
    };

    // Functions
    var saveToken = function (access_token, refresh_token, signupFlag){

        loginService.saveToken(access_token, refresh_token, signupFlag);

        loginService.login(access_token, refresh_token, signupFlag).then(function(result){
            if (result) {
                $scope.bad_credentials = false;
            }
            else{
                $scope.bad_credentials = true;
            }
        });
    };


    // Automatic login (usually after user is redirected from www.elokenz.com/account/login/)
    var accessToken = $location.search().access_token;
    var refreshToken = $location.search().refresh_token;
    var signupFlag = $location.search().signup;

    $log.debug(accessToken);
    if (accessToken==null) {
        $log.debug("We don't have Access tokens in GET");
    } else if (refreshToken==null) {
        $log.debug("We don't have Refresh tokens in GET");
    }
    else{
        $log.debug("Signup Flag : ", signupFlag);
        if (signupFlag == 1){
            $analytics.eventTrack('Signed Up');
        }
        // We have the token in get
        saveToken(accessToken,refreshToken, signupFlag);
        //$location.path('/');
        // http://localhost:9000/#/login?access_token=05f9690fb9767e4c83bb722c3fdc6d37d663323afa5af293f345cc4f3cbe&refresh_token=ee963e4bee6cd459823b697315c5e4ae67c46d26d9ceb62e823d41767ef8
    }





    $scope.tryLogin = function () {
        spinnerService.show('spinnerLogin');
        $scope.bad_credentials = false;
        var credentials = "grant_type=password&username="+encodeURIComponent($scope.login.username)+"&password="+encodeURIComponent($scope.login.password)+"&client_id=u=6f-_XwY8VJSp_Td9RoiAwpaHUIuL8fuZtbUs@A";

        var tokenRoute = ENV.userUrl + "/o/token/" ;
        var auth = Restangular.oneUrl("auth_route", tokenRoute);

        // save email for analytics
        localStorageService.set("user_email", $scope.login.username);
        $rootScope.user_email = $scope.login.username;

        // Log in www.elokenz.com and get auth token
        $log.debug("Will be using the following credentials : ",credentials);
        auth.customPOST(credentials, "", undefined, {"Content-Type": 'application/x-www-form-urlencoded;charset=UTF-8'}).then(function (data) {
            $log.debug("Sent credentials to token via POST");
            $log.debug(data);
            spinnerService.hide('spinnerLogin');
            var access_token = data.access_token;
            var refresh_token = data.refresh_token;
            saveToken(access_token, refresh_token);
            $analytics.eventTrack("Login");
        }, function(response){
            spinnerService.hide('spinnerLogin');
            $scope.bad_credentials = true;
        });
    };

  });
