'use strict';

/**
 * @ngdoc function
 * @name dashboardApp.controller:ArticleCtrl
 * @description
 * # ArticleCtrl
 * Controller of the dashboardApp - Articles
 */
angular.module('dashboardApp')
  .controller('DeckDetailCtrl', function ($scope,  Restangular, localStorageService, $routeParams, $q, $location, $analytics) {
    
    var user_id = localStorageService.get('user_elokenz_userid');
    // The base URL for all articles;  
    var base_author = Restangular.one("users",user_id); 


    // Function to transform a list of strings to a list of objects
    // input : ["chat", "chien"] 
    // ouptut : [{"name": "chat"}, {"name": "chien"}]
    var tags_list2objects = function(tagsList){
      var tags_objects = [];
      angular.forEach(tagsList, function(value, key) {
        console.log(value,key);
        tags_objects.push({"name" : value})
        return tags_objects;
      });
      console.log(tags_objects);
      return tags_objects;
    };    
    // Function to transform a a list of objects to a list of strings     
    // inptut : [{"name": "chat"}, {"name": "chien"}]
    // output : ["chat", "chien"] 
    var tags_objects2list = function(tagsObject){
      var tags_list = [];
      angular.forEach(tagsObject, function(value, key, obj) {
        console.log(value,key, obj);      
        tags_list.push(obj[key].name)
        return tags_list;
      });
      console.log(tags_list);
      return tags_list;
    };
    

    // Get Current Deck Data
    if(!isNaN($routeParams.deckId)){
      console.log("It's a integer. Let's import the deck from the API");
      // Get deck
      base_author.one("decks", $routeParams.deckId).get().then(function(deck) {
        //console.log(deck);
        $scope.deck = deck;
        $scope.deck.tags_listNgInput = tags_list2objects($scope.deck.tags_list);
        $scope.deckExist = true;

          // get mention count for last 30 days
          base_author.one("decks", $routeParams.deckId).all("metrics").customGET("articledates").then(function(data) {
              // $scope.decks[key].datesTwitter = data.datesTwitter;
              // $scope.decks[key].datesGoogle = data.datesGoogle;
              console.log(data.datesMetrics);
              $scope.deck.datesMetrics = getDatesMetrics(data.datesMetrics);
          });


      }, function(response){
        $scope.deckExist = false;
        console.log(response);
      });
      // End get deck
    } else if($routeParams.deckId === "create"){
      $scope.deckExist = false;
      
      $scope.createDeck = function(deck) {
        console.log("New deck will be saved");
        deck.tags_list = [];
        console.log(deck);
        Restangular.one("users",user_id).all("decks").post(deck).then(function(postedDeck) {
          console.log("Deck successfully posted");
          $analytics.eventTrack('Added deck');
          console.log(postedDeck);
          $scope.deckExist = true;
          var deckPath = '/decks/'+(postedDeck.id);
          console.log("Redirecting to ", deckPath);
          $location.path(deckPath);
        });
      };
    } else {
      console.log("There is a problem, the current URL doesn't resolve to a valid page.");
      $scope.deckExist = false;
    }
    
  // Update Deck
    $scope.updateDeck = function(data) {
      if (data==="name"){
        $scope.deck.patch({"name": $scope.deck.name});
      } 
      else if (data==="description"){
        $scope.deck.patch({"description": $scope.deck.description});
      } 
  };
  // End update Deck
  
    // Autocomplete
  // Get tags {
   Restangular.one("users",user_id).all("tags").getList().then(function(tags) {
        $scope.all_tags = tags
    });
    // End get tags
   $scope.loadTags = function() {
      var deferred = $q.defer();
      deferred.resolve($scope.all_tags);
      return deferred.promise;
    };
  // End autocomplete
  
  
    // POST new tag to the API
  $scope.tagAdded = function($tag){
    console.log($tag);
    console.log({"tags_list": $scope.deck.tags_listNgInput});
    $scope.deck.tags_listDjango = tags_objects2list($scope.deck.tags_listNgInput);
    $scope.deck = $scope.deck.patch({"tags_list": $scope.deck.tags_listDjango}).then(function(deck) {
      $scope.deck = deck;
      $scope.deck.tags_listNgInput = tags_list2objects($scope.deck.tags_list);
      $analytics.eventTrack('Added tag', { targetObject: 'deck'});
    });
  }; // end posting tag  
  
  
  // DELETE tag from the API
  $scope.tagRemoved = function($tag){
    console.log($tag);
    console.log({"tags_list": $scope.deck.tags_listNgInput});
    $scope.deck.tags_listDjango = tags_objects2list($scope.deck.tags_listNgInput);
    $scope.deck = $scope.deck.patch({"tags_list": $scope.deck.tags_listDjango}).then(function(deck) {
      $scope.deck = deck;
      $scope.deck.tags_listNgInput = tags_list2objects($scope.deck.tags_list);
      $analytics.eventTrack('Deleted tag', { targetObject: 'deck'});
    });
  }; // end deleting tag



  

  /******************** NVD3 *******************/
    $scope.options = {
    chart: {
        type: 'multiChart',
        //interpolate: "base",  
        //height: 450,
        //width: 300,
        margin : {
            top: 20,
            right: 70,
            bottom: 60,
            left: 70
        },
        x: function(d){ return d.x; },
        y: function(d){ return d.y; },
        color: d3.scale.category10().range(),
        useInteractiveGuideline: true,
        showValues: false,
        valueFormat: function(d){
            return d3.format(',.02f')(d);
        },
        transitionDuration: 500,
        clipEdge: true,
        xAxis: {
            axisLabel: 'Date',
            showMaxMin: false,
            tickFormat: function(d) {
                        return d3.time.format('%d %b %Y')(new Date(d));
                    }
        },

        yAxis1: {
            axisLabel: 'Count',
            rotateYLabel: false,
            tickFormat: function(d){
                return d3.format(',.0d')(d);
            }
        },
        yAxis2: {
            tickFormat: function(d){
                return d3.format(',.0d')(d);
            }
        }
    }
};

function getDatesMetrics(datesMetrics){
    var datesMetricsResult = [];
    var avgWordsArticlesResult = [];


    for (var i = 0; i < datesMetrics.length; i++) {
                var dateFormat = datesMetrics[i]["published"].replace(/-/g, "/");
                var ts = new Date(dateFormat).getTime()
                datesMetricsResult.push({x: ts, y: datesMetrics[i]["count"]});
                avgWordsArticlesResult.push({x: ts, y: datesMetrics[i]["metrics"]["words"]["average"]});
            }

    return [
                {
                    values: datesMetricsResult,      //values - represents the array of {x,y} data points
                    key: 'Articles', //key  - the name of the series.
                    area: false ,
                    yAxis: 1,
                    type: "line"
                },
                {
                    values: avgWordsArticlesResult,      //values - represents the array of {x,y} data points
                    key: 'Words per article', //key  - the name of the series.
                    area: false ,
                    yAxis: 2,
                    type: "line"
                }
            ];

}

/******************** END NVD3 ************************/

  
})

  .controller('DeckDetailCtrlSocial', function($scope,  Restangular, localStorageService, $routeParams, $q, $location, lazyLoad) {
    
    var user_id = localStorageService.get('user_elokenz_userid');
    // The base URL for all articles;  
    var base_author = Restangular.one("users",user_id); 




    $scope.loadMore = function(obj) {
        if (typeof obj !== "undefined"){
            lazyLoad(obj);
        }
   };


    // Infinite Scroll for readers
    // to use it in internal div with overflow
    // see https://github.com/sroze/ngInfiniteScroll/issues/150
    $scope.reader_fetcher_busy = false;
    $scope.loadMoreReaders = function() {
      console.log("Scrolling to the bottom, fetching more data if necessary");
      $scope.reader_fetcher_busy = true; // stop fetching data while haven't fetched more material
      if ($scope.mentions._resultmeta.next != '') {
        var $nextUrl = $scope.mentions._resultmeta.next;
        var $oldMentions = $scope.mentions;

        Restangular.allUrl(base_author, $nextUrl).getList().then(function(mentions) {
              //console.log(deck);
              var count = mentions.length;
              for (var i = 0; i < count; i++) {
                $scope.mentions.push(mentions[i]);
                //this.items.push(items[i].data);
              }
              $scope.mentions._resultmeta = mentions._resultmeta;
              $scope.reader_fetcher_busy = false; // we can fetch again next time
            }, function(response){
              console.log(response);
            });
      }
      
    };



    // Function to transform a list of strings to a list of objects
    // input : ["chat", "chien"] 
    // ouptut : [{"name": "chat"}, {"name": "chien"}]
    var tags_list2objects = function(tagsList){
      var tags_objects = [];
      angular.forEach(tagsList, function(value, key) {
        console.log(value,key);
        tags_objects.push({"name" : value})
        return tags_objects;
      });
      console.log(tags_objects);
      return tags_objects;
    };    
    // Function to transform a a list of objects to a list of strings     
    // inptut : [{"name": "chat"}, {"name": "chien"}]
    // output : ["chat", "chien"] 
    var tags_objects2list = function(tagsObject){
      var tags_list = [];
      angular.forEach(tagsObject, function(value, key, obj) {
        console.log(value,key, obj);      
        tags_list.push(obj[key].name)
        return tags_list;
      });
      console.log(tags_list);
      return tags_list;
    };
    
    
    $scope.tab = 1;
    
    $scope.setTab = function (tabId) {
        $scope.tab = tabId;
        switch(tabId){
          case 1:
            // Get deck mentions
            base_author.one("decks", $routeParams.deckId).all("mentions").getList().then(function(mentions) {
              //console.log(deck);
              $scope.mentions = mentions;
            }, function(response){
              console.log(response);
            });
            // End get mentions

            // Get Readers
            base_author.one("decks", $routeParams.deckId).all("readers").getList().then(function(data) {
              //console.log(deck);
              $scope.readers = data;
            }, function(response){
              console.log(response);
            });
            // End get deck readers
            break;
          case 2:
            // Get deck mentions
            base_author.one("decks", $routeParams.deckId).all("mentions/google").getList().then(function(mentions) {
              //console.log(deck);
              $scope.mentions = mentions;
            }, function(response){
              console.log(response);
            });
            // End get deck

            // Get Readers
            base_author.one("decks", $routeParams.deckId).all("readers/google").getList().then(function(data) {
              //console.log(deck);
              $scope.readers = data;
            }, function(response){
              console.log(response);
            });
            // End get deck readers
            break;
          case 3:
            // Get deck mentions
            base_author.one("decks", $routeParams.deckId).all("mentions/twitter").getList().then(function(mentions) {
              //console.log(deck);
              $scope.mentions = mentions;
            }, function(response){
              console.log(response);
            });
            // End get deck

            // Get Readers
            base_author.one("decks", $routeParams.deckId).all("readers/twitter").getList().then(function(data) {
              //console.log(deck);
              $scope.readers = data;
            }, function(response){
              console.log(response);
            });
            // End get deck readers
          default:
            break;
        }
    };
    $scope.isSet = function (tabId) {
        return $scope.tab === tabId;
    };
        
    
    if(!isNaN($routeParams.deckId)){
  
      console.log("It's a integer. Let's import the deck from the API");
      // Get deck
      base_author.one("decks", $routeParams.deckId).get().then(function(deck) {
        //console.log(deck);
        $scope.deck = deck;
        $scope.deck.tags_listNgInput = tags_list2objects($scope.deck.tags_list);
        $scope.deckExist = true;
      }, function(response){
        $scope.deckExist = false;
        console.log(response);
      });
      // End get deck
      
      
      // Get deck mentions
      base_author.one("decks", $routeParams.deckId).all("mentions").getList().then(function(mentions) {
        //console.log(deck);
        $scope.mentions = mentions;
      }, function(response){
        console.log(response);
      });
      // End get deck
      // Get Readers
            base_author.one("decks", $routeParams.deckId).all("readers").getList().then(function(data) {
              //console.log(deck);
              $scope.readers = data;
            }, function(response){
              console.log(response);
            });
            // End get deck readers
    } else {
      console.log("There is a problem, the current URL doesn't resolve to a valid page.");
    }



});


