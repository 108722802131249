angular.module('dashboardApp')
    .service('Tour', ['localStorageService', function (localStorageService) {
    	'use strict';

        // To configure and see available types, see documentation : https://github.com/abhikmitra/ng-joyride

        // Page Repost General
        var tourRepostGeneral = [
          {
            type: "element",
            selector: "#repostMenu_1",
            text: 'The Repost Queue lists upcoming reposts.',
            heading: "Repost Queue",
            placement: 'bottom',
            scroll: false
          },{
            type: "element",
            selector: "#repostQueue",
            text: 'Your Repost Queue displays your next week\'s scheduled reposts and your 10 last emitted reposts.',
            heading: "Repost Queue",
            placement: 'top',
            scroll: false
          },{
            type: "function",
            fn: "tourGoRepostList"
          },{
            type: "element",
            selector: "#repostMenu_2",
            text: 'The "Manage Reposts" tab lists all the reposts you have set up (even inactive ones).',
            heading: "Manage Reposts",
            placement: 'bottom',
            scroll: false
          },{
            type: "element",
            selector: "#loadingColumn",
            text: 'A Repost\'s battery charges faster when it is popular (shares, clics). Once its battery is full, the repost is scheduled again. When it is shared, the battery gets empty.',
            heading: "Battery energy",
            placement: 'top',
            scroll: false
          },{
            type: "element",
            selector: "#repostStatusItem-0",
            text: 'This box indicates if the repost has enabled messages.',
            heading: "Repost messages status",
            placement: 'bottom',
            scroll: false
          },{
            type: "function",
            fn: "tourGoCalendar"
          },{
            type: "element",
            selector: "#repostMenu_3",
            text: 'The calendar is associated to the social account selected in the left menu. Elokenz picks random slots but you can edit them.',
            heading: "Calendar",
            placement: 'bottom',
            scroll: false
          },{
            type: "function",
            fn: "tourGoSettings"
          },{
            type: "element",
            selector: "#repostMenu_4",
            text: 'In the Settings tab, enable/disable your social account and adjust the social fatigue.',
            heading: "Settings",
            placement: 'bottom',
            scroll: false
          },{
            type: "element",
            selector: "#socialFatigue",
            text: 'The social fatigue determines the battery\'s maximum charging time.<br><br>It is the time it will take for the battery to charge if not popular. If the repost gets audience feedback (shares and clics), the charging time will be reduced.',
            heading: "Social Fatigue",
            placement: 'top',
            scroll: false
          }
        ];

        // Page Repost Detail
        var tourRepostDetail = [
          {
            type: 'element',
            selector: '#repostStatusBar',
            heading: 'Status bar',
            text: 'The status bar shows you if your repost is enabled and has messages activated.',
            placement: 'bottom',
            scroll: false
          },{
            type: 'element',
            selector: '#repostBasicSettings',
            heading: 'Settings',
            text: 'Choose the repost settings. Settings affect the posting frequency.',
            placement: 'bottom',
            scroll: false
          },{
            type: 'element',
            selector: '#addMessageForm',
            heading: 'Add a message',
            text: 'Create custom new messages for any of your social accounts. <br/><br/>The Elokenz minified URL, automatically added to your status, allows us to collect the click count.',
            placement: 'bottom',
            scroll: true
          },{
            type: 'element',
            selector: '#repostMessages',
            heading: 'Messages',
            text: 'Messages will be shared on social network as status.<br>Setup several messages to see which one performs best.',
            placement: 'top',
            scroll: true
          }
        ];
        // If some messages are generated we will display this part of the tour (it should be working most of the time though)
        var tourRepostDetailMessage = [
        {
            type: 'element',
            selector: '#repostMessageItem-1 #messageAccounts',
            heading: 'Enable messages',
            text: 'To enable or disable a message, just click on a given social platform.',
            placement: 'top',
            scroll: false
          },{
            type: 'element',
            selector: '#repostMessageItem-1 #tourActionMessage',
            heading: 'Edit/Delete messages',
            text: 'You can <b>edit</b> or <b>delete</b> them if you feel they are not relevant.',
            placement: 'top',
            scroll: false
          }
        ];

        // The end of the tour
        var tourRepostDetailEnd = [
        {
            type: 'element',
            selector: '#repostStatusDot',
            heading: 'Check your status',
            text: 'Check if your repost is live in the status bar.',
            placement: 'bottom',
            scroll: true
           },{
            type: 'title',
            heading: 'You are all set!',
            text: 'You can go through this tour again at any time by selecting it in the Help Menu.',
          }
        ];

        this.getData = function(){
            return {
              'tourRepostGeneral': tourRepostGeneral,
              'tourRepostDetail': tourRepostDetail,
              'tourRepostDetailMessage': tourRepostDetailMessage,
              'tourRepostDetailEnd': tourRepostDetailEnd,
            };
          };

    }]);
