var appFilters = angular.module('limitHtmlfilters', [])

    .filter('limitHtml', function () {
        return function (text, limit) {
            var changedString = String(text).replace(/<[^>]+>/gm, '');
            return changedString.length > limit ? changedString.substr(0, limit - 1) : changedString;
        };
    })

    .filter('sourceType', function() {
      return function(text) {

            if (text==='R'){
                return 'RSS';
            }if (text==='S'){
                return 'Sitemap';
            } else{
                return text;
            }
        };
    })


    .filter('articleTitle', function() {
      return function(text) {

            if (text==='__Pending__'){
                return '[Error] No title imported';
            }
            if (text===''){
                return '[Warning] No title imported';
            } else{
                return text;
            }
        };
    })

    .filter('repostMessage', [
            '$sce',
            function($sce) {
              return function(text) {
                var urlLabel = '<span class="label label-info" title="This will be replaced by the content url" uib-popover="This will be replaced by the content url" popover-trigger="mouseenter">url</span>';
                return $sce.trustAsHtml(text.replace('{{url}}', urlLabel));
                };
            }
    ])

    .filter('numberFixedLen', function () {
        return function (n, len) {
            var num = parseInt(n, 10);
            len = parseInt(len, 10);
            if (isNaN(num) || isNaN(len)) {
                return n;
            }
            num = ''+num;
            while (num.length < len) {
                num = '0'+num;
            }
            return num;
        };
    })
;
