'use strict';

var module = angular.module('dashboardApp');
module.service('messageService', function(Restangular, localStorageService, $q, Utils, spinnerService, $routeParams, $filter, $log, $opbeat) {

  var self = this;

  // Service variables
  var userId, baseAuthor, socialAccounts, repostId, repost, messages, listedImages;

  // Return the data when getting the service object
  this.getData = function(){
    return {
      'socialAccounts': socialAccounts,
      'repost': repost,
      'messages': messages,
      'listedImages': listedImages,
    };
  };

  angular.isUndefinedOrNull = function(val) {
      return angular.isUndefined(val) || val === null;
  };


  /////////////////////////////////////////
  /////////////////////////////////////////

  this.init = function(){
    userId = localStorageService.get('user_elokenz_userid');
    baseAuthor = Restangular.one('users',userId);

    if(!isNaN($routeParams.repostId)) {
      repostId = $routeParams.repostId ;
    }
    var deferred = $q.defer();
    listedImages=[];
    baseAuthor.one('pots',repostId).get().then(function (result) {
      repost = result;
      if (repost.expiration_date !== null) {
        repost.expirationDate2 = new Date(repost.expiration_date);
        repost.shouldExpire = true;
      } else {
        repost.expirationDate2 = null;
        repost.shouldExpire = false;
      }
      if (repost.boost > 0) {
        repost.boosted = true;
      } else {
        repost.boosted = false;
      }
      socialAccounts = Utils.getData().socialAccounts;
      deferred.resolve();
    });
    return deferred.promise;
  };

  this.isMessageEnabled = function (message) {
      // Return True is the message is enabled on at least a social account
      var enabledSocialAccountCount = $filter('filter')(message.social_accounts, function (d) {return d.enabledOnMessage === true}).length;
      return (enabledSocialAccountCount > 0);
    };

  this.setMessageImageInArray = function(message){
    var mediaName = ['media1', 'media2', 'media3', 'media4'];
    var mediaList = [];
    for (var i = mediaName.length - 1; i >= 0; i--) {
      var currentMediaUrl = message[mediaName[i]];
      if (currentMediaUrl !== null){
        mediaList.push(currentMediaUrl);
        // Add curent media url to listedImages
        if (currentMediaUrl !== ''){
          self.addSuggestedImage(currentMediaUrl, false);
        }
      }
    }
    return mediaList;
  };

  this.inArray = function(array, key, value) {
    // Returns true if value is in array[i][key]
    for(var i=0;i<array.length;i++) {
      if (array[i][key] === value) {return true;}
    }
    return false;
  };

  this.addSuggestedImage = function(url, inUse){
    // Add the url to listeImage array only if the url is unique
    if (!inUse) { inUse = false; }
    if ( !self.inArray(listedImages, 'src', url)){
      listedImages.push({'src': url, 'inUse': inUse});
    }
  };

  this.setSocialAccountInfoMessage = function(message){
    var messageSocialAccounts = [];
    var currentSocialAccount = angular.copy($filter('filter')(socialAccounts, function (d) {return d.id === message.social_account;})[0]);
        if (currentSocialAccount !== undefined) {
          currentSocialAccount.enabledOnMessage = message.enabled; // is this social network currently enabled ?
          currentSocialAccount.messageId = message.id; // we keep this for further updates
          messageSocialAccounts = [currentSocialAccount];
        }
    return messageSocialAccounts;
  };

  this.OrphanError = function(message) {
    this.name = "OrphanError";
    this.message = (message || "");
  };
  this.OrphanError.prototype = Error.prototype;

  this.addAlternateMessageVersions = function(){
    // We remove all message that are alternate versions and we add the corresponding social account to main message
    for (var i = messages.length - 1; i >= 0; i--) {
      // if (messages[i].similar_to !== undefined || messages[i].similar_to !== null) {
      if (!angular.isUndefinedOrNull(messages[i].similar_to)) {
        // Adding the social account to main message
        // Only if it's enabled
        var mainMessage = $filter('filter')(messages, function (d) {return d.id === messages[i].similar_to;})[0];
        var indexMainMessage = messages.indexOf(mainMessage);
        //$log.debug("indexMainMessage : ", indexMainMessage);

        if (indexMainMessage === -1){
          messages.splice(i, 1); // This message should not be here, we delete it
          var errMsg = ['We detected an orphan message that shouldnt exist. id : ', messages[i].id, 'similar_to : ', messages[i].similar_to].join('');

          var e = new this.OrphanError(errMsg);
          $opbeat.captureException(e);
          //messages[i].similar_to = null; // FIXME : this will introduce bugs and should never happen
        }

        else{
          var currentSocialAccount = angular.copy($filter('filter')(socialAccounts, function (d) {return d.id === messages[i].social_account;})[0]);
          if (currentSocialAccount !== undefined) {
            currentSocialAccount.enabledOnMessage = messages[i].enabled; // is this social network currently enabled ?
            currentSocialAccount.messageId = messages[i].id; // we keep this for further updates
            messages[indexMainMessage].social_accounts.push(currentSocialAccount);

          }
          // Adding the emission_count and the stats for non main messages
          messages[indexMainMessage].emission_count =  messages[indexMainMessage].emission_count + messages[i].emission_count;
          messages[indexMainMessage].stats.shortUrlClicks =  (messages[indexMainMessage].stats.shortUrlClicks * 1 || 0) + (messages[i].stats.shortUrlClicks * 1 || 0); // we are forcing str to be int here
          // Removing the alternative version
          messages.splice(i, 1);
        }

      }
    }
  };

  this.addEmptySocialAccount = function(){
    for (var i = messages.length - 1; i >= 0; i--) {
      for (var j = socialAccounts.length - 1; j >= 0; j--) {
        if (messages[i].social_accounts === undefined ) {
          // no social account is in used (should be the case only in dev server)
          messages[i].social_accounts = [];
        }
        var accountsWithSameId = $filter('filter')(messages[i].social_accounts, function (d) {return d.id === socialAccounts[j].id;});
        // if (messages[i].social_accounts.indexOf($scope.social_accounts[j])==-1){
        if (accountsWithSameId.length==0) {
          // we do smth because the social account isn't in use
          var socialAccount = angular.copy(socialAccounts[j]);
          socialAccount.enabledOnMessage = undefined;
          messages[i].social_accounts.push(socialAccount);
        }
      };
    };
  };

  this.getMessages = function(){
    var deferred = $q.defer();
    // fetch and save the various messages
    baseAuthor.one('pots',repostId).all('messages').getList().then(function (result) {
      messages = result;
      // Get the main messages and images
      for (var i = messages.length - 1; i >= 0; i--) {
        // Social account part
        // Set the current social account, to display next to each message, depending on enabled/disabled
        messages[i].social_accounts = self.setSocialAccountInfoMessage(messages[i]);
        // Setting all the images in an array
        messages[i].media = self.setMessageImageInArray(messages[i]);
      }
      // Add the alternate message versions
      self.addAlternateMessageVersions();

      // Add the info about social accounts when there is no existing message
      self.addEmptySocialAccount();



      deferred.resolve();
    });
    return deferred.promise;
  };

});
