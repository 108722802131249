'use strict';

/**
 * @ngdoc overview
 * @name dashboardApp
 * @description
 * # dashboardApp
 *
 * Main module of the application.
 */
angular
  .module('dashboardApp')
  .config(function ($routeProvider) {

    $routeProvider
      .when('/', {
        title: '- Dashboard',
        templateUrl: 'components/home/homeView.html',
        controller: 'MainCtrl'
      })
      .when('/login', {
        title: '- Login',
        templateUrl: 'components/login/loginView.html',
        controller: 'LoginCtrl'
      })
      .when('/settings', {
        title: '- Settings',
        templateUrl: 'components/settings/settingsView.html',
        controller: 'SettingsCtrl',
        loginRequired: true
      })
      .when('/notifications', {
        title: '- Notifications',
        templateUrl: 'components/notifications/notificationsView.html',
        controller: 'NotificationsCtrl',
        loginRequired: true
      })
      .when('/about', {
        templateUrl: 'components/about/aboutView.html',
        controller: 'AboutCtrl'
      })
      .when('/articles', {
        title: '- Articles',
        templateUrl: 'components/articles/articlesView.html',
        controller: 'ArticleCtrl',
        loginRequired: true
      })
      .when('/articles/:articleId', {
        title: '- Articles',
        templateUrl: 'components/articleDetail/articleDetailView.html',
        controller: 'ArticleDetailCtrl',
        loginRequired: true
      })
      .when('/articles/createRepost/:articleCreateRepostId', {
        title: '- Creating a new Repost',
        templateUrl: 'components/articles/articlesView.html',
        controller: 'ArticleCtrl',
        loginRequired: true
      })
      .when('/articles/dismissRepost/:articleDismissRepostId', {
        title: '- Creating a new Repost',
        templateUrl: 'components/articles/articlesView.html',
        controller: 'ArticleCtrl',
        loginRequired: true
      })
      .when('/decks', {
        title: '- Decks',
        templateUrl: 'components/decks/decksView.html',
        controller: 'DeckCtrl',
        loginRequired: true
      })
      .when('/repost', {
        title: '- Reposts',
        templateUrl: 'components/repost/repostsView.html',
        controller: 'RepostsCtrl',
        loginRequired: true
      })
      .when('/repost/:repostId', {
        title: '- Repost Configuration',
        templateUrl: 'components/repost/repostDetailView.html',
        controller: 'RepostDetailCtrl',
        reloadOnSearch: false,
        loginRequired: true
      })
      .when('/decks/:deckId', {
        title: '- Decks',
        templateUrl: 'components/decksDetail/decksDetailView.html',
        controller: 'DeckDetailCtrl',
        loginRequired: true
      })
      .when('/decks/:deckId/social', {
        title: '- Decks',
        templateUrl: 'components/decksDetail/decksDetailSocialView.html',
        controller: 'DeckDetailCtrlSocial',
        loginRequired: true
      })
      .when('/welcome/:stepId', {
        title: '- Welcome',
        templateUrl: 'components/onboard/onboardView.html',
        controller: 'OnboardCtrl',
        loginRequired: true
      })
      .when('/upload', {
        title: '- Upload',
        templateUrl: 'components/uploadCSV/uploadCSVView.html',
        controller: 'UploadCSVCtrl',
        loginRequired: true
      })
      .when('/upgrade', {
        title: '- Upgrade',
        templateUrl: 'components/upgrade/upgradeView.html',
        controller: 'UpgradeCtrl',
        loginRequired: true
      })
      .when('/upgrade/process', {
        title: '- Thanks you for your subscription',
        templateUrl: 'components/upgrade/upgradeProcessView.html',
        controller: 'UpgradeProcessCtrl',
        loginRequired: true
      })
      .when('/linkedin/select_account', {
        title: '- Sync a new LinkedIn Account',
        templateUrl: 'components/socialAccountSelect/linkedinView.html',
        controller: 'LinkedinSelectCtrl',
        loginRequired: true
      })
      .when('/facebook/select_account', {
        title: '- Sync a new Facebook Account',
        templateUrl: 'components/socialAccountSelect/facebookView.html',
        controller: 'FacebookSelectCtrl',
        loginRequired: true
      })
      .when('/reconnection/:accountId', {
        title: '- Reconnect a Social Account',
        templateUrl: 'components/reconnect/reconnectView.html',
        controller: 'ReconnectCtrl',
        loginRequired: true
      })
      .otherwise({
        redirectTo: '/'
      });
  })
  .run(['$rootScope', '$location', function ($rootScope, $location){
    var path = function () {return $location.path(); };
    $rootScope.$watch(path, function (newVal, oldVal) {
      $rootScope.activetab = newVal;
    });
  }]);
