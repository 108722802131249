angular.module('dashboardApp')
.directive('elokenzHeader', function() {
  return {
    restrict: 'EA',
    scope: false,
    templateUrl: 'shared/header/headerView.html',
    controller: function($scope, onboardingService, $http, $log, repostService, Utils){

        // Get repost count - To display tour only on new users
        var getRepostCount = function(){
            Utils.init().then(function () {
                repostService.init().then(function(){
                    if (repostService.getData().reposts !== undefined){
                        $scope.repostcount = repostService.getData().reposts.length;
                    }
                });
            });
        };

        getRepostCount();

        $scope.$on('repostRefreshed', function() {
          getRepostCount();
        });


        // Displaying status
        $scope.status = true;

        $http.get('https://updown.io/api/checks?api-key=ro-413pcv0h1jave3eitn1z').success(function(data) {
            var flag = true;
            $log.debug("State of the service :");
            $log.debug(data);
            for (var i = data.length - 1; i >= 0; i--) {
                var works =( data[i].down === false && data[i].error === null);
                $log.debug(data[i].alias, data[i].down, data[i].error );
                flag = flag && works;
            }
            $scope.status = flag;
        });
    },
  };
});

angular.module('dashboardApp')
.directive('clickLink', ['$location', function($location) {
    return {
        link: function(scope, element, attrs) {
            element.on('click', function() {
                scope.$apply(function() {

                    if (angular.isDefined(attrs.navSection)){
                        $location.path(attrs.clickLink).search({tab: attrs.navSection});
                    } else{
                        $location.path(attrs.clickLink).search({});;
                    }
                });
            });
        }
    }
}]);
