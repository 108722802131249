/**
 * @ngdoc function
 * @name dashboardApp.controller:UploadCSVController
 * @description
 * # uploadCSVCtrl it handles the upload form for csv files to be parsed.
 */
angular.module('dashboardApp')

    .controller('LinkedinSelectCtrl', ['$scope', 'Restangular', 'localStorageService', 'Utils', '$location', '$log', 'growl', function ($scope, Restangular, localStorageService, Utils, $location, $log, growl) {
        'use strict';

        // Private variable which holds the current parsed csv data
        var baseUser;
        var userId = localStorageService.get('user_elokenz_userid');
        baseUser = Restangular.one('users', userId);

        baseUser.getList('social_accounts/linkedin')
            .then(function (response) {
                $scope.linkedinAccounts = response;
                console.log('We got it right');
            }, function (error) {
                console.log(error);
            });

        $scope.enableAccount = function(socialAccount){

            // Enable a social account, or create one
            if (socialAccount.id !== undefined && socialAccount.id !== null){
                baseUser.one('social_accounts',socialAccount.id).patch({
                    'active': true,
                    'visible': true
                }).then(function(result) {
                    $log.debug("ok good patch");
                    $location.path('/repost').search({connected: 'new'});
                }, function (error) {
                    $log.error("Patching the current linkedin page returned an error : ", error);
                });
            }
            else {
                var newSocialAccount = {
                    "name": "LP",
                    "oauth_token" : socialAccount.oauth_token,
                    "oauth_token_secret" : socialAccount.oauth_token_secret,
                    "id_str" : socialAccount.id_str,
                    "parent_id" : socialAccount.parent,
                    "timezone": "Europe/Paris",
                    "extradata" : socialAccount.extradata
                };
                baseUser.all('social_accounts').post(newSocialAccount).then(function(result) {
                    $location.path('/repost').search({connected: 'new'});
                }, function (error) {
                    growl.error("We can't add this account. Maybe you have reached the maximum number of social profiles included in your account plan.");
                    $log.error("We were not able to update this account : ", error);
                });
            }
        }

    }])


    .controller('FacebookSelectCtrl', ['$scope', 'Restangular', 'localStorageService', 'Utils', '$location', '$log', 'growl', function ($scope, Restangular, localStorageService, Utils, $location, $log, growl) {
        'use strict';

        // Private variable which holds the current parsed csv data
        var baseUser;
        var userId = localStorageService.get('user_elokenz_userid');
        baseUser = Restangular.one('users', userId);

        baseUser.getList('social_accounts/facebook')
            .then(function (response) {
                $scope.linkedinAccounts = response;
                $log.debug('We got it right');
            }, function (error) {
                $log.error(error);
            });

        $scope.enableAccount = function(socialAccount){

            // Enable a social account, or create one
            if (socialAccount.id !== undefined && socialAccount.id !== null){
                baseUser.one('social_accounts',socialAccount.id).patch({
                    "active" : true,
                    "visible" : true
                }).then(function(result) {
                    $log.debug("ok good patch");
                    $location.path('/repost').search({connected: 'new'});
                }, function (error) {
                    $log.error("Patching the current facebook page returned an error : ", error);
                });
            }
            else {
                var newSocialAccount = {
                    'name': 'FP',
                    'oauth_token' : socialAccount.oauth_token,
                    'oauth_token_secret': socialAccount.oauth_token_secret,
                    'id_str': socialAccount.id_str,
                    'timezone': 'Europe/Paris',
                    'parent_id': socialAccount.parent,
                    'extradata': socialAccount.extradata
                };
                baseUser.all('social_accounts').post(newSocialAccount).then(function(result) {
                    $location.path('/repost').search({connected: 'new'});
                }, function (error) {
                    growl.error("We can't add this account. Maybe you have reached the maximum number of social profiles included in your account plan.");
                    $log.error("We were not able to update this account : ", error);
                });
            }
        }

    }]);
