'use strict';

/**
 * @ngdoc function
 * @name dashboardApp.controller:ArticleCtrl
 * @description
 * # ArticleCtrl
 * Controller of the dashboardApp - Articles
 */
angular.module('dashboardApp')
  .controller('ArticleDetailCtrl', function($scope,  Restangular, localStorageService, $routeParams, $q, $location) {
    
    var user_id = localStorageService.get('user_elokenz_userid');
    // The base URL for all articles;  
    var base_author = Restangular.one("users",user_id); 

    // Function to transform a list of strings to a list of objects
    // input : ["chat", "chien"] 
    // ouptut : [{"name": "chat"}, {"name": "chien"}]
    var tags_list2objects = function(tagsList){
      var tags_objects = [];
      angular.forEach(tagsList, function(value, key) {
        console.log(value,key);
        tags_objects.push({"name" : value})
        return tags_objects;
      });
      console.log(tags_objects);
      return tags_objects;
    };    
    // Function to transform a a list of objects to a list of strings     
    // inptut : [{"name": "chat"}, {"name": "chien"}]
    // output : ["chat", "chien"] 
    var tags_objects2list = function(tagsObject){
      var tags_list = [];
      angular.forEach(tagsObject, function(value, key, obj) {
        console.log(value,key, obj);      
        tags_list.push(obj[key].name)
        return tags_list;
      });
      console.log(tags_list);
      return tags_list;
    };
    
    if(!isNaN($routeParams.articleId)){
      // Get deck
      Restangular.one("pages", $routeParams.articleId).all("mentions").customGET().then(function(mentions_data) {
        $scope.mentions = mentions_data.mentions;
        $scope.mentions.count = mentions_data.count;
        
      }, function(response){
        console.log(response);
      });
      // End get deck
    };
      
      
    // Get mentions
    base_author.one("articles", $routeParams.articleId).get().then(function(article) {
        //console.log(deck);
        $scope.article = article;
        // Add metrics to a special variable (this allows to share widgets with decksController)
        // var $scope.deck ;
        // $scope.deck.metrics = article.metrics;
      }, function(response){
        console.log(response);
      });
    // End get mentions    
  
  
});

