'use strict';

var module = angular.module('dashboardApp');

module.service('repostService', function(Restangular, localStorageService, $q, Utils, spinnerService, $location, $filter, $log, growl, onboardingService) {

  var self = this;

  // Service variables
  var  userId, baseAuthor, socialAccounts, activeAccounts, reposts, bubbles, queue, cachedData, queueCount, nextQueueItemsURL;
  var initialized = false;
  var cachePreloaded = false;

  // Return the data when getting the service object
  this.getData = function(){
    return {
      'socialAccounts': socialAccounts,
      'reposts': reposts,
      'bubbles': bubbles,
      'queueCount': queueCount,
      'queue': queue
    };
  };

  /////////////////////////////////////////
  /////////////////////////////////////////

  this.isUrl = function(str) {
    var urlRegex = '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
    var url = new RegExp(urlRegex, 'i');
    return str.length < 2083 && url.test(str);
  };

  this.isMinified = function(url) {
    var categories = ['bit.ly', 'j.mp', 'fb.me', 'buff.ly', 'goo.gl', 'scifri.me', 'ow.ly', 'ryte.ly', 'amzn.com', 'amzn.to', 'bit.do', 't.co', 'lkdn.in', 'db.tt', 'qr.ae', 'adf.ly', 'bitly.com', 'cur.lv', 'tinyurl.com', 'ity.im', 'q.gs', 'is.gd', 'po.st', 'bc.vc', 'twitthis.com', 'u.to', 'buzurl.com', 'cutt.us', 'u.bb', 'yourls.org', 'x.co', 'scrnch.me', 'filoops.info', 'vzturl.com', 'qr.net', '1url.com', 'tweez.me', 'v.gd', 'tr.im', 'y2u.be', 'youtu.be', ' tcrn.ch'];
    for (var i = categories.length - 1; i >= 0; i--) {
      if (url.indexOf('//' + categories[i]) !== -1){
        return false;
      }
    }
    return true;
  };

  this.checkUrl = function(url){
    var isValid = true;
    if (!self.isUrl(url)){
      growl.error('This is not a valid URL (check that you have http)');
      isValid = false;
    }
    if (!self.isMinified(url)){
      growl.error('This is a minified URL. Sorry we can\'t use that. Please use the long-form url (canonical url).');
      isValid = false;
    }

    return isValid;
  };

  this.updateCache = function(){
    if (!angular.isUndefinedOrNull(Utils.getActiveSocialAccountId())){
      var activeAccountID = Utils.getActiveSocialAccountId();


      cachedData[activeAccountID] = {'reposts': reposts};
      cachedData[activeAccountID].reposts = reposts;
      cachedData[activeAccountID].bubbles = bubbles;
      cachedData[activeAccountID].queueCount = queueCount;
      cachedData[activeAccountID].queue = queue;

      localStorageService.set('cachedDataRepostService', cachedData);
    }
  };


  this.getCache = function(){
    $log.debug(cachedData);

    // No Cached Data
    if (angular.isUndefinedOrNull(cachedData)){
      return {
        'reposts': null,
        'bubbles': null,
        'queueCount': null,
        'queue': null
      };
    }

    // If there is no active social account
    if (angular.isUndefinedOrNull(Utils.getActiveSocialAccountId())){
      // $log.debug("In the wrong branch");
      return {
        'reposts': null,
        'bubbles': null,
        'queueCount': null,
        'queue': null
      };
    }
    var activeAccountID = Utils.getActiveSocialAccountId();

    // We have the data for the given social account, let's preload them
    if (!angular.isUndefinedOrNull(cachedData[activeAccountID])){
      // $log.debug("In the correct branch", activeAccountID, cachedData[activeAccountID].queue.length);
      return {
        'reposts': cachedData[activeAccountID].reposts,
        'bubbles': cachedData[activeAccountID].bubbles,
        'queueCount': cachedData[activeAccountID].queueCount,
        'queue': cachedData[activeAccountID].queue
      };
    } else{ // We have cached data, but not for this social account
      // $log.debug("In the wrong branch 2");
      return {
        'reposts': null,
        'bubbles': null,
        'queueCount': null,
        'queue': null
      };
    }
  };

  // Get active posts
  this.getPots = function(activeAccountID) {
    try{
      spinnerService.show('spinnerRepostList');
    } catch(err){
      $log.debug(err);
    }
    var deferred = $q.defer();
    baseAuthor.all('pots').getList({social_account: activeAccountID}).then(function (result) {
    //baseAuthor.all('pots').one('next_on_profile', activeAccountID).getList().then(function (result) { // long version that refreshes all the time
      reposts = result;
      try{
        spinnerService.hide('spinnerRepostList');
      } catch(err){
        $log.debug(err);
      }
      self.updateCache();
      deferred.resolve(result);
    });
    return deferred.promise;
  };

  // Delete Repost
  this.deleteRepost = function(repost, activeAccountID) {
    var deferred = $q.defer();
    baseAuthor.one('pots', repost.id).remove({social_account: activeAccountID}).then(function() {
          var repostInArray = $filter('filter')(reposts, function (d) {return d.id === repost.id;});
          reposts.splice(reposts.indexOf(repostInArray), 1);
          growl.success('Repost deleted!');
          self.updateCache();
          deferred.resolve();
          self.sendRepostRefeshEvent();
        }, function(response){
          $log.error(response);
          growl.error('There was an error. We could not delete this repost!');
          deferred.reject(response);
        });
    return deferred.promise;
  };

  // Get bubbles
  this.getBubbles = function(activeAccountID) {
    try{
      spinnerService.show('spinnerBubbleList');
    } catch(err){
      $log.debug(err);
    }
    var deferred = $q.defer();
    baseAuthor.all('bubbles').getList({social_account: activeAccountID}).then(function (result) {
      bubbles = result;
      self.updateCache();
      deferred.resolve(result);
      try{
      spinnerService.hide('spinnerBubbleList');
      } catch(err){
        $log.debug(err);
      }
    });
    return deferred.promise;
  };

  // Get queue
  this.getQueue = function(activeAccountID) {
    try {
      spinnerService.show('spinnerQueueList');
    }catch(err){
      $log.debug('No spinner spinnerQueueList defined');
    }
    // spinnerService.show('spinnerQueueList');
    var deferred = $q.defer();
    var toUTCDate = function(date){
      var _utc = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),  date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
      return _utc;
    };
    baseAuthor.one('social_accounts', activeAccountID).all('queue').getList().then(function (result) {
      // console.log("jice", result._resultmeta.count);
      queueCount = result._resultmeta.count;
      nextQueueItemsURL = result._resultmeta.next;
      for (var i = result.length - 1; i >= 0; i--) {
          if (result[i].emission_date_user_local !== null){
          var timeOffset = result[i].emission_date_user_local.substr(result[i].emission_date_user_local.length - 6).replace(':','');
          result[i].tzOffset=timeOffset; //dateEmission.getTimezoneOffset();
        }
      }
      // Order chronologically (see http://stackoverflow.com/q/10123953/2550237)
      queue = result.sort(function(a,b) {a = new Date(a.emission_date_user_local); b = new Date(b.emission_date_user_local); return (a < b) ? 1 : ((b < a) ? -1 : 0);} );

      try {
        spinnerService.hide('spinnerQueueList');
      }catch(err){
        $log.debug('No spinner spinnerQueueList defined');
      }
      self.updateCache();
      deferred.resolve([queue, queueCount]);
      // spinnerService.hide('spinnerQueueList');
    });
    return deferred.promise;
  };

  this.getNextQueueItems = function(activeAccountID){
    try {
      spinnerService.show('spinnerQueueList');
    }catch(err){
      $log.debug('No spinner spinnerQueueList defined');
    }
    var deferred = $q.defer();
    var baseApi = Restangular.all(''); // hack for Restangular to call allUrl
    Restangular.allUrl(baseApi, nextQueueItemsURL).getList().then(function (result) {
      queueCount = result._resultmeta.count;
      nextQueueItemsURL = result._resultmeta.next;

      for (var i = result.length - 1; i >= 0; i--) {
          if (result[i].emission_date_user_local !== null){
          var timeOffset = result[i].emission_date_user_local.substr(result[i].emission_date_user_local.length - 6).replace(':','');
          result[i].tzOffset=timeOffset; //dateEmission.getTimezoneOffset();
        }
      }

      // We concat with existing queue
      result = result.concat(queue);
      // We order chronologically (see http://stackoverflow.com/q/10123953/2550237)
      queue = result.sort(function(a,b) {a = new Date(a.emission_date_user_local); b = new Date(b.emission_date_user_local); return (a < b) ? 1 : ((b < a) ? -1 : 0);} );

      try {
        spinnerService.hide('spinnerQueueList');
      }catch(err){
        $log.debug('No spinner spinnerQueueList defined');
      }
      deferred.resolve(queue);

    }, function (response){
        console.log(response);
    });
    return deferred.promise;

  };


  // Send Queue Item Now
  this.sendQueueItemNow = function(queueItemID) {
    var activeAccountID = Utils.getActiveSocialAccountId();
    var deferred = $q.defer();
    if (queueItemID > -1){
      baseAuthor.one('social_accounts', activeAccountID).one('queue', queueItemID).all('post_now').getList().then(function (result) {
        queue = result.sort(function(a,b) {a = new Date(a.emission_date_user_local); b = new Date(b.emission_date_user_local); return (a < b) ? 1 : ((b < a) ? -1 : 0);} );
        self.updateCache();
        deferred.resolve(result);
      }, function (error) {
        // promise rejected, could log the error with: console.log('error', error);
        $log.error('sendQueueItemNow : ', error);
        growl.error('We couldn\'t post this queue item right now. Something went wrong');
        deferred.reject(error);
      });
    }
    return deferred.promise;
  };


  // Send Queue Item Now
  this.setCustomTimeQueueItem = function(queueItemID, customDate) {
    var activeAccountID = Utils.getActiveSocialAccountId();
    var deferred = $q.defer();
    if (queueItemID > -1){
      baseAuthor.one('social_accounts', activeAccountID).one('queue', queueItemID).patch({
          'emission_date': customDate
        }).then(function (result) {
          deferred.resolve(result);
      }, function (error) {
        // promise rejected, could log the error with: console.log('error', error);
        $log.error('setCustomTimeQueueItem : ', error);
        growl.error('We couldn\'t change the emission time. Something went wrong');
        deferred.reject(error);
      });
    }
    return deferred.promise;
  };

  // Send Queue Item Now
  this.changeQueueItemIndex = function(queueItemID, newQueueIndex) {
    var activeAccountID = Utils.getActiveSocialAccountId();
    var deferred = $q.defer();
    if (queueItemID > -1){
      baseAuthor.one('social_accounts', activeAccountID).one('queue', queueItemID).patch({
          'queue_index': newQueueIndex
        }).then(function (result) {
          deferred.resolve(result);
      }, function (error) {
        // promise rejected, could log the error with: console.log('error', error);
        $log.error('changeQueueItemIndex : ', error);
        growl.error('We couldn\'t change the order of publication. Something went wrong');
        deferred.reject(error);
      });
    }
    return deferred.promise;
  };


  // Can the user add a new social account with his current situation
  this.canAddSocialAccount = function(accountName, user){
      var socialAccountCount = $filter('filter')(socialAccounts, function (d) {return d.visible === true;});
      var hasTwitterAccount = $filter('filter')(socialAccounts, function (d) {return (d.visible === true && d.name === 'T');});
      var hasFacebookAccount = $filter('filter')(socialAccounts, function (d) {return (d.visible === true && d.name.indexOf('F') > -1 );}); // True if finds  "FB" or "F"

      // Case user if FREE
      if (user.account.name === 'F' && hasTwitterAccount.length !== 0  && accountName === 'twitter') {
        return false;
      }
      if (user.account.name === 'F' && hasFacebookAccount.length !== 0 && accountName === 'facebook') {
        return false;
      }
      // Otherwise
      if (socialAccountCount.length >= user.account.max_social_profiles){
        return false;
      }
      if (accountName === 'facebook' && user.account.facebook_account){
        return true;
      }
      if (accountName === 'twitter' && user.account.twitter_account){
        return true;
      }
      if (accountName === 'linkedin' && user.account.linkedin_account){
        return true;
      }
      if (accountName === 'google' && user.account.google_account){
        return true;
      }
      return false;
  };

  // Get  socialAccounts when the page initialize
  this.getAllRepostData = function(force) {
    var deferred = $q.defer();
    if (force !== true ) {
      force = false;
    }
    $log.debug("getAllRepostData : ", userId);
    Utils.getSocialAccounts(baseAuthor, force)
      .then(function (result) {
        socialAccounts = result;
        if (socialAccounts.length !== 0){
          Utils.updateActiveAccounts().then(function (result) {
            activeAccounts = result;
            var activeAccountID = Utils.getActiveSocialAccountId();
            var promises = [self.getPots(activeAccountID),self.getBubbles(activeAccountID),self.getQueue(activeAccountID)];
            $q.all(promises)
              .then(function() {
                try{
                  spinnerService.hide('spinnerSettings');
                } catch(err){
                  $log.debug(err);
                }
                deferred.resolve();
              });

          });
        }
      }, function (error) {
        // promise rejected, could log the error with: console.log('error', error);
        $log.error('getAllRepostData : ', error);
        deferred.reject(error);
      });
      return deferred.promise;
  };

  this.preLoadCache = function(){
    if ( !cachePreloaded || !angular.isUndefinedOrNull(cachePreloaded) ){
      if (localStorageService.get('cachedDataRepostService')){
        // $log.debug('Cached Data Preloaded');
        cachedData = localStorageService.get('cachedDataRepostService');
        cachePreloaded = true;
      }
    }
  };

  this.sendRepostRefeshEvent = function(){
        // send the signal back to the directive :
        //get the topmost scope
        var $scope = angular.element(document).scope();
        // new step event
        var event = 'repostRefreshed';
        var args = [];
        $scope.$broadcast(event, args);
    };

  this.init = function(){
    var deferred = $q.defer();
    var force;
    // We'll force the update of Social Media if an url parameter is present
    var revoked = ($location.search()).revoked;  // we can redirect to social tab directly
    var connected = ($location.search()).connected;  // we can redirect to social tab directly

    if (connected !== undefined){
      onboardingService.addSuccessStep('newSocialAccount'); // Add a new successful step to onboarding if necessary
    }
    if (revoked !== undefined || connected !== undefined){
      force = true;
    } else{
      force = false;
    }
    if ( !initialized || force ){
      cachedData = {};
      userId = localStorageService.get('user_elokenz_userid');
      if (userId === null) {
        // We don't have a user ID yet (landing/login screen) so don't initialize anything
        $log.debug("initialize all repost : ", userId);
        deferred.resolve();
        return deferred.promise;
      }
      // The base URL for all articles;
      baseAuthor = Restangular.one('users',userId);

      self.getAllRepostData(force).then(function (result){
        deferred.resolve(result);
        initialized = true;
      });
    } else{
      deferred.resolve();
    }
    return deferred.promise;
  };

  this.reset = function(){
    userId = baseAuthor = socialAccounts = activeAccounts = reposts = bubbles = queue = queueCount = cachedData = nextQueueItemsURL = null;
    initialized = cachePreloaded = false;
  };


});
