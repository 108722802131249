'use strict';

angular.module('dashboardApp')
  .controller('ReconnectCtrl', function ($scope, Restangular, ENV, $routeParams, Utils, localStorageService, $location, growl) {
    //var user_id = localStorageService.get('user_elokenz_userid');

    var userId = localStorageService.get('user_elokenz_userid');
    var user = localStorageService.get('user');

    // The base URL for all articles;

    if(!isNaN($routeParams.accountId)) {
      var accountId = $routeParams.accountId ;
      $scope.accountId = accountId;
    }
    console.log(accountId);
    console.log(userId);


    var baseAuthor = Restangular.one('users',userId);

    baseAuthor.one('social_accounts', accountId).all('reconnect_check').get([]).then(function (result) {
      $scope.success = result.result;
      if ($scope.success) {

        growl.success('Your account has been successfully reconnected');
        $location.path('repost').search('connected', 'new');
      }
    }, function (response) {
      $scope.success = false;
      growl.error('There was a problem when trying to reconnect your account');
      console.log(response);
    }); // End get author


    // Get  socialAccounts
    Utils.init().then(function () {
      $scope.social_accounts = Utils.getData().socialAccounts;
      $scope.active_accounts = Utils.getData().activeAccounts;
    }, function(error) {
        // promise rejected, could log the error with: console.log('error', error);
        console.log('error' , error);
    });




  })
;
