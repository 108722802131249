'use strict';

/**
 * @ngdoc overview
 * @name dashboardApp
 * @description
 * # dashboardApp
 *
 * Main module of the application.
 */
angular
    .module('dashboardApp', [
        'ngAnimate',
        'config',
        'ngCookies',
        'ngMessages',
        'ngResource',
        'ngRoute',
        'ngSanitize',
        'ngTouch',
        'ui.sortable',
        'ui.bootstrap',
        'ui.scrollpoint',
        'LocalStorageModule',
        'infinite-scroll',
        'restangular',
        'ngTagsInput',
        'angular-growl',
        'xeditable',
        'angulartics',
        'angulartics.google.tagmanager',
        'angulartics.segment',
        'angularFileUpload',
        'limitHtmlfilters',
        'lazyModule',
        'nvd3',
        'ngJoyRide',
        'frapontillo.bootstrap-switch',
        'ui.bootstrap-slider',
        'angularSpinner',
        'angular-timezone-selector',
        'iso-3166-country-codes',
        'angularGrid',
        'ngOpbeat',
        'angularSpinners',
        'ui.bootstrap.datetimepicker',
        'dndLists'
    ])

    .config(function ($routeProvider, RestangularProvider) {

        RestangularProvider.setBaseUrl('http://papi.elokenz.com');
        RestangularProvider.setRequestSuffix('/?format=json');

        //To get cookies to work:
        //RestangularProvider.setDefaultHttpFields({withCredentials: true});

        // This function is used to map the JSON data to something Restangular
        // expects (required because we use Django Rest Framework and paginated data)
        // We also add a 'scrollBusy' flag for ngInfiniteScroll
        RestangularProvider.setResponseExtractor(function (response, operation, what, url) {
            if (operation === "getList") {
                // Use results as the return type, and save the result metadata
                // in _resultmeta
                if (_.has(response,'results')) {
                    var newResponse = response.results;
                    newResponse._resultmeta = {
                        "count": response.count,
                        "next": response.next,
                        "previous": response.previous,
                        "scrollBusy": false
                    };
                    return newResponse;
                }
                else {
                    return response;
                }
            }
            return response;
        });
    })

    // Set base url for API endpoint (in production, this will likely be http://papi.elokenz.com)
    .run(function (Restangular, ENV) {
        Restangular.setBaseUrl(ENV.papiUrl);
    })

    // Remove log debug in production
    .config(['$logProvider', 'ENV' , function ($logProvider, ENV) {
        if (ENV.isProduction) {
            $logProvider.debugEnabled(false);
        } else {
            $logProvider.debugEnabled(true);
        }
    }])
    // Remove log debug in production
    .config(['$opbeatProvider', 'ENV' , function ($opbeatProvider, ENV) {
        if (ENV.isProduction) {
                $opbeatProvider.config({
                    orgId: '849f8a04447847309eae832d9fd7ce3d',
                    appId: '06c23a1527'
                });
            }
    }])


    .config(['localStorageServiceProvider', function (localStorageServiceProvider) {
        localStorageServiceProvider.setPrefix('ls');
    }])

    // Is connected
    .run(function ($rootScope, localStorageService, $location, $log, loginService) {

        $rootScope.user_is_logged_in = false;

        if (localStorageService.get("access_token")) {
            $rootScope.user_is_logged_in = true;
        }

        // Set the rootscope based on localstorage
        if (localStorageService.get("user_email")) {
            $rootScope.user_email = localStorageService.get("user_email");
        }
        if (localStorageService.get("user_elokenz_userid")) {
            $rootScope.user_elokenz_userid = localStorageService.get("user_elokenz_userid");
        }


        // Logout
        $rootScope.logout = function () {
            loginService.logout();
        };
    })


    // Don't show the login page if connected
    .run(function ($rootScope, localStorageService, $location) {
        if ($rootScope.user_is_logged_in) {
            if ($location.path() == "/login") {
                $location.path('/');
            }
        };
    })

    // Growl Configuration
    .config(['growlProvider', function (growlProvider) {
        growlProvider.globalTimeToLive(5000);
    }])


    // Send the bearer token with all connection
    .run(function (Restangular , $location, localStorageService, $log) {
        // Send access_token
        if (localStorageService.get("access_token")) {
            var access_token = localStorageService.get("access_token");
            $log.debug("access_token : "+access_token);
            Restangular.setDefaultHeaders({Authorization:'Bearer '+ access_token});
        }
    })

    // Put the user object in rootscope from localstorage at each page load (warning : page load != page visit)
    // Poll the /users/me endpoint every time
    .run(function (loginService) {
        loginService.init();
        loginService.canConnect();
    })

    // Send the analytics info with all connections
    .run(function ($analytics, localStorageService, $rootScope, $log) {

        // Get userid
        if (localStorageService.get("user_elokenz_userid")) {
            // Identify for analytics
            var userid = localStorageService.get("user_elokenz_userid");
        }

        // Get email
        if (localStorageService.get("user_email")) {
            $log.debug("We have the user via localStorage.\nWe should identify him");
            var user_email = localStorageService.get("user_email");
            //analytics.identify([userId], [traits], [options], [callback]);
        }
        else if ($rootScope.user_email != null) {
            $log.debug("we have the user via rootScope, we should identify him");
            var user_email = $rootScope.user_email;
            //analytics.identify([userId], [traits], [options], [callback]);
        }

        // Get name
        if (localStorageService.get('user')) {
            $log.debug("Got the user from LS to put analytics");
            var user = localStorageService.get('user');
            var username = user.first_name + ' ' + user.last_name;
            var userCreationDate = user.created_at;
        }
        else if ($rootScope.user != null) {
            $log.debug("Got the user from Rootscope to put analytics");
            var user = $rootScope.user;
            var username = user.first_name + ' ' + user.last_name;
            var userCreationDate = user.created_at;
        }
        else {
            $log.error("Could not get the user for analytics");
            var username = user_email;
            var userCreationDate = new Date();
        }

        // angulartics
        if (userid !== undefined){
            analytics.identify(userid);
            $analytics.setUserProperties({"email": user_email, "name": username});

        }

        // Google Analytics
        $analytics.settings.ga = {
          userId: userid
        };

        // GTM
        var dataLayer = window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            "email": user_email, "username": username, "created_at": userCreationDate
            });
    })

    // Redirect users back to previous page if login was needed
    .run(function ($location, $rootScope, loginService) {
        var postLogInRoute;
        $rootScope.$on('$routeChangeStart', function (event, nextRoute, currentRoute) {
        //if login required and you're logged out, capture the current path
            if (nextRoute.loginRequired && !loginService.isLoggedIn()) {
              postLogInRoute = $location.path();
              $location.path('/login').replace();
            } else if (postLogInRoute && loginService.isLoggedIn()) {
        //once logged in, redirect to the last route and reset it
              $location.path(postLogInRoute).replace();
              postLogInRoute = null;
            }
        });
    })

    // Redirect to the login page when not connected
    .run(function (Restangular , $location, localStorageService, $rootScope, $log, loginService) {

        // Errors
        Restangular.setErrorInterceptor(function (response, deferred, responseHandler) {
            switch(response.status) {
                case 401:
                    loginService.tryRefreshingToken().then(function (result) {
                        return true;
                    }, function (error) {
                        $log.error(error);
                        return true;
                    });
                    break;

                case 403:
                    $log.warn("You are not authorized to access this resource");
                    $log.warn(response.data.error);

                default:
                    $log.error("An unknown error happened with your request\n\n Response :", response.status);
                    return true;
                    break;
            }
        });
    })

    // Template for editable field (xeditable)
    .run(function (editableOptions) {
        editableOptions.theme = 'bs3';
    })

    // Refresh tokens when we launch the page to extend connection
    // .run(function (loginService) {
    //     loginService.tryRefreshingToken();
    // })


    // Config angulartics GTM


    // Change Current Page sTitle
    .run(['$rootScope', '$log', function ($rootScope, $log) {
        $rootScope.$on('$routeChangeSuccess', function (event, current, previous) {
            $rootScope.title = current.$$route.title;
            try {
                drift.page($rootScope.title);
            } catch(err) {
                $log.error(err.message);
            }
        });
    }]);
