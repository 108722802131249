/**
 * @ngdoc function
 * @name dashboardApp.factory:Utils
 * @description
 * # This service keeps commonly used functions in one place.
 */
angular.module('dashboardApp')
    .service('Utils', ['localStorageService', '$q', 'Restangular', '$location', '$log', '$filter', function (localStorageService,$q, Restangular, $location, $log, $filter) {
    	'use strict';

    	var self = this;
        // Service variables
        var userId, baseAuthor, activeAccountId, socialAccounts, activeAccountIdx, activeAccounts;
        var initialized = false;
        var cachePreloaded = false;

        // Return the data when getting the service object
        this.getData = function(){
            return {
              'socialAccounts': socialAccounts,
              'activeAccountId': activeAccountId,
              'activeAccounts': activeAccounts,
              'activeAccountIdx': activeAccountIdx,
            };
        };


        this.getSocialAccounts = function (force) {
            // If force=false : we get social accounts from localStorage
            // otherwise we fetch the api

            self.preLoadCache(); // Preload the other data such as activeId
            force = typeof force !== 'undefined' ? force : false; // define default value
            var q = $q.defer();

            if (localStorageService.get("social_accounts") && force===false) {
                socialAccounts = localStorageService.get('social_accounts');
                self.syncActiveAccount();
                q.resolve(socialAccounts);
            } else {
                baseAuthor.all('social_accounts').getList()
                .then(function (result) {
                    socialAccounts = result;
                    localStorageService.set('social_accounts', socialAccounts);
                    self.syncActiveAccount();
                    q.resolve(socialAccounts);
                }, function (error) {
                    q.reject(error);
                });
            }
            return q.promise;
        };

        this.retrieveSocialAccounts = function (baseUser) {
            var q = $q.defer();

            baseUser.all('social_accounts').getList()
            .then(function (result) {
                socialAccounts = result;
                localStorageService.set('social_accounts', socialAccounts);
                q.resolve(socialAccounts);
            }, function (error) {
                q.reject(error);
            });

            return q.promise;
        };

        this.saveSocialAccounts = function () {
            localStorageService.set('social_accounts', socialAccounts);
            localStorageService.set('social_accounts_active', activeAccounts);
            localStorageService.set('activeAccountIdx', activeAccountIdx);
            localStorageService.set('activeAccountId', activeAccountId);
            return null;
        };

        this.preLoadCache = function(){
            if ( !cachePreloaded || !angular.isUndefinedOrNull(cachePreloaded) ){
                if (localStorageService.get('activeAccountId')){
                    activeAccountId = localStorageService.get('activeAccountId');
                }
                if (localStorageService.get('activeAccountIdx')){
                    activeAccountIdx = localStorageService.get('activeAccountIdx');
                } else {
                    activeAccountIdx = 0;
                }
                if (localStorageService.get('user_elokenz_userid')){
                    userId = localStorageService.get('user_elokenz_userid');
                }
                if (localStorageService.get('social_accounts_active')){
                    activeAccounts = localStorageService.get('social_accounts_active');
                } else {
                    activeAccounts =  activeAccounts !== null ? activeAccounts : [];
                }

                cachePreloaded = true;
            }
          };

        // Get the current socialAccount id (or default to first one)
        this.getActiveSocialAccountId = function(){
            if (!angular.isUndefinedOrNull(activeAccountId)){
                return activeAccountId;
            } else {
                    return null;

            }
        };
        // Get the current socialAccount id (or default to first one)
        this.getActiveSocialAccount = function(){
            return socialAccounts[activeAccountIdx];
        };

        // Switch the id of the current selected social account
        this.switchCurrentAccount = function(accountIdx){
          if (socialAccounts[activeAccountIdx] !== undefined){
                socialAccounts[activeAccountIdx].isSelected = false;
            }
          activeAccountIdx = accountIdx;
          activeAccountId = socialAccounts[accountIdx].id;
          socialAccounts[accountIdx].isSelected = true;
          self.saveSocialAccounts();
        };

        this.getActiveAccounts = function(){
            activeAccounts = localStorageService.get('social_accounts_active');
            activeAccounts =  activeAccounts !== null ? activeAccounts : [];
        };

        this.syncActiveAccount = function () {
            if (socialAccounts.length >0) {
                // self.getActiveAccounts();
                if (!angular.isUndefinedOrNull(activeAccountIdx)){
                    if (!angular.isUndefinedOrNull(socialAccounts[activeAccountIdx])){
                        socialAccounts[activeAccountIdx].active_repost_message = true;
                        socialAccounts[activeAccountIdx].isSelected = true;
                        activeAccountId = socialAccounts[activeAccountIdx].id;
                    }

                }
                // if (activeAccounts.length > 0){
                //     for (var i = socialAccounts.length - 1; i >= 0; i--) {
                //         if(activeAccounts.indexOf(socialAccounts[i].id ) > -1){
                //             socialAccounts[i].active_repost_message = true;
                //             activeAccountId = socialAccounts[i].id;
                //             activeAccountIdx = i;
                //             socialAccounts[activeAccountIdx].isSelected = true;
                //         } else {
                //             socialAccounts[i].active_repost_message = false;
                //         }
                //     }
                // }
                else {
                    socialAccounts[0].active_repost_message = true;
                    activeAccountId = socialAccounts[0].id;
                }
                self.saveSocialAccounts();
            }
            return socialAccounts;
        };

        this.updateActiveAccounts = function () {

            // There are two kinds of active accounts
            // 1 - The ones active in a Repost Message edition
            // 2 - The single one active in the repost left column

            // 1 - Save in localstorage the list of active social accounts 'social_accounts_active'
            var activeAccountsLocal = [];
            var q = $q.defer();
            if (socialAccounts.length === 1){
                activeAccountsLocal.push(socialAccounts[0].id);
            } else {
                for (var i = socialAccounts.length - 1; i >= 0; i--) {
                    if (socialAccounts[i].active_repost_message===true ) {
                        activeAccountsLocal.push(socialAccounts[i].id);
                    }
                }
            }
            activeAccounts = activeAccountsLocal;
            $log.debug('updateActiveAccounts : ', activeAccounts);

            // 2 - If we have at least one social account, we set it to correct selected
            activeAccountId = self.getActiveSocialAccountId();
            if (socialAccounts.length !== 0){
                if (activeAccountIdx === undefined){
                    socialAccounts[0].isSelected = true;
                } else {
                    socialAccounts[activeAccountIdx].isSelected = true;
                }
            } else {
                $log.debug('No social account connected yet');
            }
            $log.debug('updateActiveAccounts : ', activeAccounts);
            self.saveSocialAccounts();
            q.resolve(activeAccounts);
            return q.promise;
        };


        this.getAccountIdxInSocialAccounts = function(account) {
            // $filter('filter')(socialAccounts, function (d) {return d.id === account.id;})[0];
            var tmpId = account.id;
            var currentAccount = $filter('filter')(socialAccounts, function (d) {return d.id === tmpId;})[0];

            $log.debug('getAccountIdxInSocialAccounts : ', account.id);
            $log.debug('getAccountIdxInSocialAccounts : ', currentAccount);
            return socialAccounts.indexOf(currentAccount);
        };

        this.getAccountIdxInSocialAccountsFromId = function(accountId) {
            // $filter('filter')(socialAccounts, function (d) {return d.id === account.id;})[0];
            var currentAccount = $filter('filter')(socialAccounts, function (d) {return d.id === accountId;})[0];
            return socialAccounts.indexOf(currentAccount);
        };

        this.changeActiveSocialAccount = function (account){
            // Set the list of active accounts (during message edition)
            var q = $q.defer();
            var tmpIndex = self.getAccountIdxInSocialAccounts(account);
            $log.debug('changeActiveSocialAccount : ', account);
            $log.debug('changeActiveSocialAccount : ', tmpIndex);
            if (angular.isUndefined(account.active_repost_message)) {
                account.active_repost_message = true; // If the flag is not set, we define it to True
            } else {
                account.active_repost_message = !account.active_repost_message; // otherwise we flip the flag
            }
            socialAccounts[tmpIndex] = account;
            // self.updateAccountInSocialAccounts(account);
            // localStorageService.set('social_accounts', socialAccounts);
            self.updateActiveAccounts().then(function (result) {
                activeAccounts = result;
                q.resolve();
              });
            return q.promise;
        };

        // Switch the id of the current selected social account
        this.enableSocialAccount = function(state){
          var account = socialAccounts[activeAccountIdx];
          var deferred = $q.defer();
          if (account !== undefined && socialAccounts !== undefined ) {

            baseAuthor
            .one('social_accounts', account.id)
            .patch({
              'active': state,
            }).then(function (result) {
              socialAccounts[activeAccountIdx].active = result.active;
              self.saveSocialAccounts();
              deferred.resolve();
            }, function (response) {
              if (response.status === 403) {
                account.active = false;
                socialAccounts[activeAccountIdx] = account;
                self.saveSocialAccounts();
              }
              deferred.reject(response);
            });
          } else{
            deferred.reject('Account is undefined');
          }
          return deferred.promise;
        };

        // Switch the link hiding of the current selected social account
        this.hideLinks = function(state){
          var account = socialAccounts[activeAccountIdx];
          var deferred = $q.defer();
          if (account !== undefined && socialAccounts !== undefined ) {

            baseAuthor
            .one('social_accounts', account.id)
            .patch({
              'show_link_in_share': state,
            }).then(function (result) {
              socialAccounts[activeAccountIdx].show_link_in_share = result.show_link_in_share;
              self.saveSocialAccounts();
              deferred.resolve();
            }, function (response) {
              if (response.status === 403) {
                account.show_link_in_share = true;
                socialAccounts[activeAccountIdx] = account;
                self.saveSocialAccounts();
              }
              deferred.reject(response);
            });
          } else{
            deferred.reject('Account is undefined');
          }
          return deferred.promise;
        };

        // Switch the id of the current selected social account
        // TO FINISH
        this.enableAutopilot = function(state){
          var account = socialAccounts[activeAccountIdx];
          var deferred = $q.defer();
          if (account !== undefined && socialAccounts !== undefined ) {

            baseAuthor
            .one('social_accounts', account.id)
            .patch({
              'autopilot': state,
            }).then(function (result) {
              socialAccounts[activeAccountIdx].autopilot = result.autopilot;
              self.saveSocialAccounts();
              deferred.resolve();
            }, function (response) {
              if (response.status === 403) {
                account.autopilot = false;
                socialAccounts[activeAccountIdx] = account;
                self.saveSocialAccounts();
              }
              deferred.reject(response);
            });
          } else{
            deferred.reject('Account is undefined');
          }
          return deferred.promise;
        };

        this.init = function(){
            var deferred = $q.defer();
            self.preLoadCache();
            $log.debug("Initiat Service User Social Account : ", userId);
            if (userId === undefined) {
                // We don't have any userid, we don't try to fetch social accounts
                deferred.resolve();
                return deferred.promise;
            }
            baseAuthor = Restangular.one('users',userId);

            var revoked = ($location.search()).revoked;  // we can redirect to social tab directly
            var connected = ($location.search()).connected;  // we can redirect to social tab directly
            var force;
            if (revoked !== undefined || connected !== undefined){
              force = true;
            } else{
              force = false;
            }
            if ( !initialized || force ){
                $log.debug("Social Account service initializing");
                socialAccounts = null;
                self.getSocialAccounts(force).then(function(){
                    initialized = true;
                    deferred.resolve();
                });
            } else {
                deferred.resolve();
            }
            return deferred.promise;

          };


      this.reset = function(){
        userId = baseAuthor = activeAccountId = socialAccounts = activeAccountIdx = activeAccounts = null;
        initialized = cachePreloaded = false;
      };


    }]);
