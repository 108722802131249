'use strict';

/**
 * @ngdoc function
 * @name dashboardApp.controller:LoginCtrl
 * @description
 * # LoginCtrl
 * Controller of the dashboardApp
 */
angular.module('dashboardApp')
  .controller('OnboardCtrl', function ($scope, Restangular, $interval, localStorageService, $analytics, $rootScope, ENV, $routeParams, $location, $log, growl, $filter) {
    $scope.access = false;
    $scope.articles = [];
    localStorageService.set('onboardingDone', false);
    var user_id = localStorageService.get('user_elokenz_userid');

    // When an error message is present, let's disply a growl message
    if ($location.search()["error"] === "account_already_exist"){
        growl.error("This Twitter account is already in use, either by you or by someone else. You need to connect another Twitter account, or to log in with your previous account. If you have trouble connecting, send us an email : support@elokenz.com", {ttl: -1});
    };

    // The base URL for all articles;
    var sessionRoute = ENV.userUrl + '/is_connected/' ;
    $scope.twitterConnection = ENV.userUrl + '/accounts/twitter/login/?process=connect';
    Restangular.one('users', 'me').get().then(function(result){
        $scope.access = true;
        console.log('connected');
        $rootScope.user = result;
        $scope.user = result;


        // Redirect to correct onboarding page
        var user = result
        // if (user.articles.length == 0  && user.twitter_oauth_token==null){
        //     $location.path('/welcome/1');
        // }

        // else if (user.articles.length == 0){
        //     $location.path('/welcome/2');
        // }

        // Get the current onboarding step
        if(!isNaN($routeParams.stepId)){
            $scope.stepId = $routeParams.stepId ;
            // save step reached
            $rootScope.user.actions.onboarding_step_reached = $scope.stepId;
            user.actions.onboarding_step_reached = $scope.stepId;
            localStorageService.set("user", user);
            console.log($rootScope.user.onboarding_step_reached);
            Restangular.one('users', $scope.user.elokenz_userid).one('actions').patch(
                {
                    'onboarding_step_reached': $scope.stepId
                }
            );
          };



    }, function(response){
        $scope.access = false;
        console.log(response);
    }); // End get author




    // Page 1 - Twitter Sync

    $scope.update_twitter_follow = function() {
        //base_author.put(endpoint_author, $scope.author);
        Restangular.one('users', $scope.user.elokenz_userid).patch(
            {
                'twitter_follow_us': $scope.user.twitter_follow_us
            }
            );
      };



    // Page 2 - Articles add

    if(!isNaN($routeParams.stepId) && $routeParams.stepId==='2'){

        var base_author = Restangular.one('users',user_id);

        // Save the action for onboarding - Twitter synced
        base_author.one('actions').patch(
            {
                'connected_twitter_account': true
            }
        );
        try {
          $rootScope.user.actions.connected_twitter_account = true;
        }
        catch(err) {
        $log.error(err.message);
        }
        // END Save Onboarding action

          // Calendar step
  ////////////////////////

  // Get one socialAccount
  // var base_author = Restangular.one("users",user_id);
  var socialAccountId;
  base_author.all("social_accounts").getList().then(function(social_accounts) {
    localStorageService.set("social_accounts", social_accounts);
    $scope.social_account = social_accounts[0];
    socialAccountId = $scope.social_account.id ;

  }, function(response){
    console.log(response);
  });
  $scope.days = [
      {'name': 'Monday', 'dayOfWeek':0},
      {'name': 'Tuesday', 'dayOfWeek':1},
      {'name': 'Wednesday', 'dayOfWeek':2},
      {'name': 'Thursday', 'dayOfWeek':3},
      {'name': 'Friday', 'dayOfWeek':4},
      {'name': 'Saturday', 'dayOfWeek':5},
      {'name': 'Sunday', 'dayOfWeek':6}
    ] ;
  // End get one socialAccount

  // Raising a pending_flag while fetching the calendar
  var stop;
  $scope.waitingCalendar = true;
  $scope.newCalendarFetching = function() {
    // Don't start a new fetch if we are already fetching
    if ( angular.isDefined(stop) ) return;
    console.log('entered the interval');
    stop = $interval(function() {
      base_author.all('slots').getList().then(function(slots) {
        $scope.slots = $filter('filter')(slots, { social_account: $scope.social_account.id });

        if ($scope.slots.length >= 4){
          $scope.waitingCalendar = false;
          $scope.stopCalendarFetching();
        }
      });
    }, 3000, 60); // do it every 2 seconds,  30 times max
  };


  $scope.stopCalendarFetching = function() {
    if (angular.isDefined(stop)) {
      $interval.cancel(stop);
      stop = undefined;
    }
  };

  $scope.$on('$destroy', function() {
    // Make sure that the interval is destroyed too
    $scope.stopCalendarFetching();
  });

  // Triggers fetching on page 2
  if(!isNaN($routeParams.stepId) && $routeParams.stepId==='2'){
    $scope.newCalendarFetching();
  }

  // End of Calendar Fetching




  $scope.editingSlot = function(slot) {

      slot.date = new Date();
      slot.date.setHours( slot.hour );
      slot.date.setMinutes( slot.minute );

      slot.editing = true;
      console.log("editing");
    }

    $scope.updateSlot = function(slot) {
        slot.hour = slot.date.getHours();
        slot.minute = slot.date.getMinutes();

        var slotTime = {
                'hour': slot.hour,
                'minute': slot.minute
            };

        base_author.one("slots", slot.id).patch(
          slotTime
            ).then(function() {
              console.log("time of slot updated");
            }, function(response){
              console.log(response);
            });

      };

    $scope.deleteSlot = function(slot) {

        base_author.one("slots", slot.id).remove().then(function() {
              $scope.slots.splice($scope.slots.indexOf(slot), 1);
              console.log("slot deleted");
            }, function(response){
              console.log(response);
            });

      };


    $scope.addSlot = function(day) {
        var timezone = $scope.social_account.timezone;
        var social_fatigue = $scope.social_account.social_fatigue;

        var slotToAdd = {
                'day_of_week': day,
                'hour': null,
                'minute': null,
                'social_account': socialAccountId
            };
        base_author.all("slots").post(
          slotToAdd
            ).then(function(slot) {
              $scope.slots.push(slot);
              $scope.editingSlot(slot);
            }, function(response){
              console.log(response);
            });
      };


    $scope.updateSocialAccount = function(id) {
        var timezone = $scope.social_account.timezone;
        var social_fatigue = $scope.social_account.social_fatigue;
        base_author.one("social_accounts", id).patch(
            {
                'timezone': timezone,
                'social_fatigue': social_fatigue
            }
            );
      };


    }



});

