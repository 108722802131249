'use strict';

angular.module('dashboardApp')
  .controller('RepostsCtrl', function ($scope,  Restangular, localStorageService, calendarService, $analytics, ENV, $uibModal, Utils, growl, $filter, $log, $window, $location, repostService, $rootScope, Tour) {

    $scope.twitterConnection = ENV.userUrl + '/accounts/twitter/login/?process=connect';
    $scope.linkedinConnection = ENV.userUrl + '/accounts/linkedin/login/?process=connect';
    $scope.facebookConnection = ENV.userUrl + '/accounts/facebook/login/?process=connect';
    $scope.googleConnection = ENV.userUrl + '/accounts/google/login/?process=connect';
    $scope.revokeTwitterConnection = ENV.userUrl + '/accounts/connections/revoke/twitter';
    $scope.revokeLinkedinConnection = ENV.userUrl + '/accounts/connections/revoke/linkedin';
    $scope.revokeFacebookConnection = ENV.userUrl + '/accounts/connections/revoke/facebook';
    $scope.revokeGoogleConnection = ENV.userUrl + '/accounts/connections/revoke/google';
    $scope.changePassword = ENV.userUrl + '/accounts/password/change/';

    $scope.showSpinnerMyRepost = true;
    $scope.showSpinnerPastRepost = true;
    $scope.showSpinnerSettings = true;

    var userId = localStorageService.get('user_elokenz_userid');
    var user = localStorageService.get('user');

    // The base URL for all articles;
    var baseAuthor = Restangular.one('users',userId);


    // Instanciate the values from cache if available

    Utils.preLoadCache();
    repostService.preLoadCache();
    $scope.pots = repostService.getCache().reposts;
    $scope.bubbles = repostService.getCache().bubbles;
    $scope.queue = repostService.getCache().queue;
    $scope.queueCount = repostService.getCache().queueCount;
    $scope.queueFiltered = $filter('filter')($scope.queue, function (d) {return d.enabled === true; });
    $scope.queueFilteredGhost = $scope.queueFiltered;

    Utils.init().then(function () {
      $scope.social_accounts = Utils.getData().socialAccounts;
      $scope.active_next_account = Utils.getData().activeAccountIdx;
      $scope.social_account = Utils.getActiveSocialAccount();
      if ($scope.social_accounts === undefined || $scope.social_accounts.length===0){
        $scope.setTab(5);
      }
      if ($scope.social_account !== undefined ){
        // We fetch calendar slots only if there is a social account
        calendarService.init($scope.social_account.id).then(function(){
          $scope.slots = calendarService.getData().slots;
        });
      }

      // If used in URL, we switch to the active social account
      var socialAccountId = ($location.search()).account;  // we can redirect to social tab directly
      if (socialAccountId !== null && socialAccountId !== undefined) {
        var idx = Utils.getAccountIdxInSocialAccountsFromId(parseInt(socialAccountId));
        if (idx !== -1){
          $scope.switchNextRepostAccount(idx);
        }
      }


    });

    repostService.init().then(function () {
      $scope.pots = repostService.getData().reposts;
      $scope.bubbles = repostService.getData().bubbles;
      $scope.queue = repostService.getData().queue;
      $scope.queueCount = repostService.getData().queueCount;
      $scope.queueFiltered = $filter('filter')($scope.queue, function (d) {return d.enabled === true; });
      $scope.queueFilteredGhost = $scope.queueFiltered;
      $scope.days = calendarService.getData().days;
    });

    // Get active posts
    function getPots() {
      var activeAccountID = Utils.getActiveSocialAccountId();
      $scope.pots = repostService.getCache().reposts;
      $log.debug("We used the cache : ", $scope.pots)
      repostService.getPots(activeAccountID).then(function (pots) {
        $scope.pots = pots;
        // send a 'repostRefreshed' broadcast to the header for the tour (for new users)
        repostService.sendRepostRefeshEvent();
      });

    }

    // Get queue
    function getQueue(getCache) {
      getCache = typeof getCache !== 'undefined' ? getCache : true;
      var activeAccountID = Utils.getActiveSocialAccountId();
      if (getCache){
        $scope.queue = repostService.getCache().queue;
        $scope.queueCount = repostService.getCache().queueCount;
        $scope.queueFiltered = $filter('filter')($scope.queue, function (d) {return d.enabled === true; });
        $scope.queueFilteredGhost = $scope.queueFiltered;
      }
      // Get queue
      repostService.getQueue(activeAccountID).then(function (result) {
        $scope.queue = result[0];
        $scope.queueCount = result[1];
        $scope.queueFiltered = $filter('filter')($scope.queue, function (d) {return d.enabled === true; });
        $scope.queueFilteredGhost = $scope.queueFiltered;

      });
    }
    // Define the list of enabled queue items automatically whenever queue is modified
    // $scope.$watch('queue', function(queue) {
    //     $scope.queueFiltered = $filter('filter')($scope.queue, function (d) {return d.enabled === true; });
    //     $scope.queueFilteredGhost = $scope.queueFiltered;
    // }, true);

    $scope.loadMoreQueue = function(){
      repostService.getNextQueueItems().then(function (queue) {
        $scope.queue = queue;
        $scope.queueFiltered = $filter('filter')($scope.queue, function (d) {return d.enabled === true; });
        $scope.queueFilteredGhost = $scope.queueFiltered;
      });
    };

    // Get active posts
    function getBubbles() {
      var activeAccountID = Utils.getActiveSocialAccountId();
      $scope.bubbles = repostService.getCache().bubbles;
      // Get bubbles
      repostService.getBubbles(activeAccountID).then(function (bubbles) {
        $scope.bubbles = bubbles;
      });
    }


    // Switch the next repost for a given account
    $scope.switchNextRepostAccount = function (account) {
      Utils.switchCurrentAccount(account);
      $scope.social_accounts = Utils.getData().socialAccounts;
      $scope.active_next_account = Utils.getData().activeAccountIdx;
      // Calendar
      $scope.social_account = Utils.getActiveSocialAccount();
      calendarService.setSocialAccount($scope.social_account.id);
      $scope.slots = calendarService.getOneSlot();
      getPots();
      getBubbles();
      getQueue();
    };

    // Delete a Repost
    $scope.deleteRepost = function (repost) {
      repost.isDeleting = true;
      repostService.deleteRepost(repost, $scope.social_account.id).then(function () {
        $log.debug("Repost deleted - controller");
        $analytics.eventTrack('Deleted Repost');
        $scope.pots = repostService.getData().reposts;
        getQueue(); // refresh queue list
        repost.isDeleting = false;
      }, function (response) {
        repost.isDeleting = false;
      });
    };

    // Watcher for page setting and switch "enabled"
    $scope.enableSocialAccount = function(state) {
      Utils.enableSocialAccount(state).then(function () {
        $scope.social_accounts = Utils.getData().socialAccounts;
        getQueue(); // refresh queue list
        if (state){
          $analytics.eventTrack('Enabled Social Account');
        } else {
          $analytics.eventTrack('Disabled Social Account');
        }

        }, function (response) {
          $log.error('Error while switching social account : ', response);
          if (response.status === 403) {
            $scope.social_accounts = Utils.getData().socialAccounts;
            $scope.warningCantConnectSocialAccount();
          }
        });
    };


    // Watcher for page setting and switch links "enabled"
    $scope.hideLinks = function(state) {
      Utils.hideLinks(state).then(function () {
        getQueue(); // refresh queue list
      }, function (response) {
          $log.error('Error while hiding links : ', response);
          if (response.status === 403) {
            $scope.social_accounts = Utils.getData().socialAccounts;
            $scope.warningCantConnectSocialAccount();
          }
        });
    };


    // Watcher for page setting and switch "autopilot"
    $scope.enableAutopilot = function(state) {
      Utils.enableAutopilot(state).then(function () {
        $scope.social_accounts = Utils.getData().socialAccounts;
        getQueue(); // refresh queue list
        if (state){
          $analytics.eventTrack('Enabled Autopilot');
        } else {
          $analytics.eventTrack('Disabled Autopilot');
        }

        }, function (response) {
          $log.error('Error while switching social account : ', response);
          if (response.status === 403) {
            $scope.social_accounts = Utils.getData().socialAccounts;
            $scope.warningCantConnectSocialAccount();
          }
        });
    };

    $scope.resyncSocialAccount = function(accountName, accountId){

      var socialRoutes = {
        'google': ["Google+", $scope.googleConnection],
        'twitter': ["Twitter", $scope.twitterConnection],
        'linkedin': ["Linkedin", $scope.linkedinConnection],
        'facebook': ["Facebook", $scope.facebookConnection],
      };

      var route = socialRoutes[accountName][1] + '&next=%2Faccounts%2Freconnect%2F' + accountId;
        $analytics.eventTrack('Reconnect Social Account', {platform: route[0]});
        $window.location.href = route;
        return;

    };


    $scope.addSocialAccount = function(accountName){
      var canAddSocialAccountFlag = repostService.canAddSocialAccount(accountName,user);
      var socialRoutes = {
        'google': ["Google+", $scope.googleConnection],
        'twitter': ["Twitter", $scope.twitterConnection],
        'linkedin': ["Linkedin", $scope.linkedinConnection],
        'facebook': ["Facebook", $scope.facebookConnection],
      };

      if (canAddSocialAccountFlag) {
        var route = socialRoutes[accountName];
        $analytics.eventTrack('Connect Social Account', {platform: route[0]});
        $window.location.href = route[1];
        return;
      }
      $scope.warningCantConnectSocialAccount();
    };

    $scope.update_twitter_follow = function() {
      Restangular.one('users', $scope.user.elokenz_userid).patch({
        'twitter_follow_us': $scope.user.twitter_follow_us
      });
    };

    $scope.suggestedTimeslot = function(account){
      if (account === undefined) return 0;
      var active_repost = account.active_repost_count;
      if (account.name == 'T') {
        var min_range = 10;
        var medium_range = 20;
        var max_range = 50;
      } else {
        var min_range = 20;
        var medium_range = 50;
        var max_range = 150;
      }

      if (active_repost < min_range) {
        return 1
      }
      if (active_repost < medium_range) {
        return 2
      }
      if (active_repost < max_range) {
        return 3
      }
    }

    // Internal Menu
    $scope.myRepost = true;

    $scope.show = function (item) {
      $scope.myRepost = false;
      $scope.emittedRepost = false;
      if (item === "myRepost") {
        $scope.myRepost = true;
      } else {
        $scope.emittedRepost = true;
      }
    };

    // Enable/Disable Repost
    $scope.enableRepost = function (repost,flag) {
      repost.enabled = flag;
      repost.patch({
        'enabled': flag
      }).then(function () {
        $scope.needToRefresh = true;
        getQueue();
      });
    };

    // Update a Repost
    $scope.updateRepost = function(repost){
      baseAuthor.one("pots", repost.id).patch(
          {
          'enabled': repost.enabled
          }
      ).then(function () {
        getQueue();
      });
    }; // end update a repost

    // Send Now - ItemQueue
    $scope.postNow = function(queueItemId){
      $log.debug("posting now");
      repostService.sendQueueItemNow(queueItemId).then(function (result) {
        getQueue();
      });
    }; // end send now

    // Send Now - ItemQueue
    $scope.setItemQueueCustomTime = function(newDate, oldDate, queueItemId){
      repostService.setCustomTimeQueueItem(queueItemId, newDate).then(function (result) {
        getQueue();
      });
      // close toggle
      var toggleID = "#setCustomTime" + queueItemId;
      var toggle2ID = "#toggleTime" + queueItemId;
      $(toggleID).attr('aria-expanded','false');
      $(toggle2ID).removeClass( 'open');
    }; // end send now



    $scope.updateSocialAccount = function (singleAccount) {
      // See http://stackoverflow.com/a/24393892/2550237
      // var single_account = $filter('filter')($scope.social_accounts, function (d) {return d.id === id; })[0];
      var timezone = singleAccount.timezone;
      var socialFatigue = singleAccount.social_fatigue;
      Restangular
        .one('users', $scope.user.elokenz_userid)
        .one("social_accounts", singleAccount.id)
        .patch({
          'timezone': timezone,
          'social_fatigue': socialFatigue
        }).then(function () {
          growl.success('Settings updated');
          Utils.retrieveSocialAccounts(baseAuthor);
          $analytics.eventTrack('Updated Social Account Settings');
          // Utils.retrieveSocialAccounts(baseAuthor).then(function(result){$scope.social_accounts = result});

        });
    };

    $scope.saveSettings = function () {
      Restangular
        .one('users', $scope.user.elokenz_userid)
        .patch({
          'settings_email_newsletter': $scope.user.settings_email_newsletter,
          'bubble_per_day_twitter': $scope.user.bubble_per_day_twitter,
          'twitter_social_fatigue': $scope.user.twitter_social_fatigue,
          'bubble_per_day_google': $scope.user.bubble_per_day_google,
          'bubble_per_day_facebook': $scope.user.bubble_per_day_facebook,
          'settings_email_notifications': JSON.stringify($scope.user.settings_email_notifications),
          'settings_email_weeklyRepostStats': JSON.stringify($scope.user.settings_email_weeklyRepostStats)
        }).then(function () {
          growl.success('Settings updated');
          Utils.retrieveSocialAccounts(baseAuthor);
        });
    };


    // Calendar
    ///////////

    $scope.editingSlot = function(slot) {
      slot.date = new Date();
      slot.date.setHours( slot.hour );
      slot.date.setMinutes( slot.minute );
      slot.editing = true;
      $scope.currentlyEditingSlot = true;
      getQueue();
    }

    $scope.updateSlot = function(slot){
      $scope.currentlyEditingSlot = false;

      calendarService.updateSlot(slot).then(function(){
        getQueue();
      });
      calendarService.updateUserOnboardingAccount();
    };

    $scope.deleteSlot = function(slot){
      calendarService.deleteSlot(slot).then(function () {
        $log.debug("slot deleted - controller");
        $scope.slots = calendarService.getOneSlot();
        calendarService.updateUserOnboardingAccount();
        getQueue();
      });
    };

    $scope.addSlot = function(day){
      calendarService.addSlot(day).then(function (slot) {
        $scope.slots = calendarService.getOneSlot();
        $scope.editingSlot(slot);
        getQueue();
      });
    };

    // End Calendar
    ///////////


    // JoyRide Tour
    ///////////

    $scope.startTour = function () {
      $scope.tab = 1;
      var configTour = Tour.getData().tourRepostGeneral;
      $scope.configTour = configTour;
      $scope.startJoyRide = true;
    };

    $scope.onFinish = function () {
      // we could add some analytics tracking here to see how many launched the tour
    };

    // See http://stackoverflow.com/a/26042432/2550237 and note on Evernote 'angular rootscope'
    $rootScope.getMyCtrlScope = function() {
      return $scope;
    }


    $scope.tourGoRepostList = function (bool) {
      if (bool) {
        $scope.tab = 2;
      } else {
        $scope.tab = 1;
      }
    };

    $scope.tourGoCalendar = function (bool) {
      if (bool) {
        $scope.tab = 3;
      } else {
        $scope.tab = 2;
      }
    };

    $scope.tourGoSettings = function (bool) {
      if (bool) {
        $scope.tab = 4;
      } else {
        $scope.tab = 3;
      }
    };

    // Settings
    ///////////
    $scope.go_revoke_account = function (socialAccount) {
      Restangular
        .one('users', $scope.user.elokenz_userid)
        .one('social_accounts', socialAccount.id)
        .remove()
        .then(function (result_sa) {
          switch(socialAccount.name) {
            case "T":
              $analytics.eventTrack('Disconnect Social Account', {platform: 'Twitter'});
              $window.location.href = $scope.revokeTwitterConnection;
              break;
            case "F":
              $analytics.eventTrack('Disconnect Social Account', {platform: 'Facebook'});
              $window.location.href = $scope.revokeFacebookConnection;
              break;
            case "L":
              $analytics.eventTrack('Disconnect Social Account', {platform: 'Linkedin'});
              $window.location.href = $scope.revokeLinkedinConnection;
              break;
            case "G":
              $analytics.eventTrack('Disconnect Social Account', {platform: 'Google'});
              $window.location.href = $scope.revokeGoogleConnection;
              break;
            default:
              $window.location.reload();
              break;
          }
        }, function (response) {
          growl.error("Account was not correctly deleted");
        });
    };
    // End Settings
    ///////////////


    $scope.articles = [];

    // Open Bulk
    $scope.openBulk = function (size) {
      if ($scope.user.account.name === 'F'){
        $scope.warningCantBulk();
      }
      else{
        $location.path("/upload/");
      }
    };
    // Open Modal
    $scope.open = function (size) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'components/repost/myModalContent.html',
        controller: 'ModalInstanceCtrl',
        size: size,
        resolve: {
          articles: function () {
            return $scope.articles;
          }
        }
      });
    };

    // Menu
    $scope.tab = 1;
    $scope.isSet = function (tabId) {
      return $scope.tab === tabId;
    };
    $scope.setTab = function (tabId) {
      $scope.tab = tabId;
      if (tabId == 5 && $scope.active_next_account !== undefined) {
        if ($scope.social_accounts !== undefined && $scope.social_accounts[$scope.active_next_account] !== undefined){
          $scope.social_accounts[$scope.active_next_account].isSelected=false;
        }
      }
    };

    var tabSocial = ($location.search()).tab;  // we can redirect to social tab directly
    if (tabSocial === 'social') {
      $scope.setTab(5);
    }
    if (tabSocial === 'add') {
      $scope.open();
    }
    if (tabSocial === 'queue') {
      $scope.setTab(1);
    }
    if (tabSocial === 'resync') {
      $scope.setTab(1);
    }
    if (tabSocial === 'reposts') {
      $scope.setTab(2);
    }
    if (tabSocial === 'calendar') {
      $scope.setTab(3);
    }
    if (tabSocial === 'settings') {
      $scope.setTab(4);
    }



    $scope.warningCantConnectSocialAccount = function (size) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'components/repost/partials/modalSocialAccountLimit.html',
        controller: 'ModalInstanceCtrlArticles',
        size: size
      });
    };

    $scope.warningCantBulk = function (size) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'components/repost/partials/modalSocialAccountLimit.html',
        controller: 'ModalInstanceCtrlArticles',
        size: size
      });
    };




    // Drag and Drop of Queue Items
    $scope.logListEvent = function(action, index, external, type) {
        var message = external ? 'External ' : '';
        message += type + ' element was ' + action + ' position ' + index;
        console.log(message);
    };
    $scope.dropCallback = function(index, item, external, type) {

        // Get the new queue_index
        var current_idx = arrayObjectIndexOf($scope.queueFilteredGhost,item);
        // $log.debug("Current Index before dragdrop : " + current_idx);
        // // $scope.logListEvent('dropped at', index, external, type);
        // $log.debug(item.id + ' previously had queue_index of ' + item.queue_index);
        var old_queue_item_index = item.queue_index;
        if (current_idx > index){
          $log.debug('Is replacing item : ' + $scope.queueFiltered[index].id);
          var new_queue_item_index = $scope.queueFiltered[index].queue_index;
        } else{
          $log.debug('Is replacing item : ' + $scope.queueFiltered[index-1].id);
          var new_queue_item_index = $scope.queueFiltered[index-1].queue_index;
        }

        // Fake false time
        for (var i = $scope.queueFiltered.length - 1; i >= 0; i--) {
          if (!$scope.queueFiltered[i].custom_time && $scope.queueFiltered[i].queue_index >= Math.min(old_queue_item_index, new_queue_item_index)){
            $scope.queueFiltered[i].emission_date_user_local = null;
          }
        }
        item.emission_date_user_local = null;

        // Fetch the real queue and time
        repostService.changeQueueItemIndex(item.id, new_queue_item_index).then(function(){
                   getQueue(false);
                }, function (response) {
                  getQueue();
                });

        // Now we refresh the list to take into account
        // $scope.queueFilteredGhost = $scope.queueFiltered;

        // Return false here to cancel drop. Return true if you insert the item yourself.
        return item;
    };



    // Finds an item in a list, without using hashkey (see http://stackoverflow.com/a/23120529/2550237)
    function arrayObjectIndexOf(arr, obj){
        for(var i = 0; i < arr.length; i++){
            if(angular.equals(arr[i], obj)){
                return i;
            }
        };
        return -1;
    }


    // Repost optimization
    $scope.repostOptimization = function(calendarSlots, socialFatigue, nbRepost){
      return calendarSlots * socialFatigue - nbRepost;
    }


  });
