/**
 * @ngdoc function
 * @name dashboardApp.factory:CSVReader
 * @description
 * # These factories should handle csvReading.
 */
angular.module('dashboardApp')
    .factory('CSVReader', ['$q', function ($q) {
        'use strict';

        var self = {};

        var onLoad = function(reader, deferred, scope) {
            return function () {
                scope.$apply(function () {
                    deferred.resolve(reader.result);
                });
            };
        };

        var onError = function (reader, deferred, scope) {
            return function () {
                scope.$apply(function () {
                    deferred.reject(reader.result);
                });
            };
        };

        var onProgress = function(reader, scope) {
            return function (event) {
                scope.$broadcast('fileProgress',
                    {
                        total: event.total,
                        loaded: event.loaded
                    });
            };
        };

        var getReader = function(deferred, scope) {
            var reader = new FileReader();
            reader.onload = onLoad(reader, deferred, scope);
            reader.onerror = onError(reader, deferred, scope);
            reader.onprogress = onProgress(reader, scope);
            return reader;
        };

        self.readAsText = function (file, scope) {
            var deferred = $q.defer();

            var reader = getReader(deferred, scope);
            reader.readAsText(file);

            return deferred.promise;
        };

        return self;
    }])

    .factory('CSVParser', [function () {
        'use strict';

        var self = {};

        /**
         * Parse CSV file
         * @param: {String} - the csv file as a string
         * @returns {Array} array of values
         * Proudly copy pasted from stackoverflow.com/questions/1293147/javascript-code-to-parse-csv-data
         * Pattern can be found there: http://i.imgur.com/SZPjHwz.jpg
         */
        self.parse = function (str) {
            var arr = [];
            var quote = false;  // true means we're inside a quoted field

            var row = 0, col = 0, c;
            // iterate over each character, keep track of current row and column (of the returned array)
            for (c = 0; c < str.length; c++) {
                var cc = str[c], nc = str[c + 1];      // current character, next character
                arr[row] = arr[row] || [];             // create a new row if necessary
                arr[row][col] = arr[row][col] || '';   // create a new column (start with empty string) if necessary

                // If the current character is a quotation mark, and we're inside a
                // quoted field, and the next character is also a quotation mark,
                // add a quotation mark to the current column and skip the next character
                if (cc === '"' && quote && nc === '"') { arr[row][col] += cc; ++c; continue; }

                // If it's just one quotation mark, begin/end quoted field
                if (cc === '"') { quote = !quote; continue; }

                // If it's a comma and we're not in a quoted field, move on to the next column
                if (cc === ',' && !quote) { ++col; continue; }

                // If it's a newline and we're not in a quoted field, move on to the next
                // row and move to column 0 of that new row
                if (cc === '\n' && !quote) { ++row; col = 0; continue; }

                // Otherwise, append the current character to the current column
                arr[row][col] += cc;
            }
            return arr;
        };

        /**
         * This ensure we send correctly formatted data in our post request
         * @param: {Array} our csv data in an array. (one element = array)
         * @returns {Array} array of object with data
         */
        self.formatForPost = function (arrayCSV) {
            var data = [], csvData, obj, index, urlDict = {}, totalUrlCount;

            for (var i = 0; i < arrayCSV.length; i++) {
                csvData = arrayCSV[i];
                obj = {
                    content: csvData[0] + ' {{url}}',
                    url: csvData[1]
                };
                for (var k = 0; k < 3; k++) {
                    if (csvData[2 + k]) {
                        index = 1 + k;
                        obj['media' + index] = csvData[2 + k];
                    }
                }
                data.push(obj);
                urlDict[obj.url] = obj.url;
            }

            totalUrlCount = Object.keys(urlDict).length;

            return [data, totalUrlCount];
        };

        return self;
    }]);
