'use strict';

/**
 * @ngdoc function
 * @name dashboardApp.controller:MainCtrl
 * @description
 * # MainCtrl
 * Controller of the dashboardApp
 */
angular.module('dashboardApp')
  .controller('MainCtrl', function ($scope, Restangular, ENV, $uibModal, Utils) {
    //var user_id = localStorageService.get('user_elokenz_userid');
    $scope.signupUrl = ENV.userUrl + '/accounts/signup/';
    Restangular.one('users', 'me').get().then(function (result) {
      $scope.access = true;
      $scope.user = result;
    }, function (response) {
      $scope.access = false;
      console.log(response);
    }); // End get author


    // Get  socialAccounts
    Utils.init().then(function () {
      $scope.social_accounts = Utils.getData().socialAccounts;
      $scope.active_accounts = Utils.getData().activeAccounts;
    }, function(error) {
        // promise rejected, could log the error with: console.log('error', error);
        console.log('error' , error);
    });


    // Open Modal
    $scope.open = function () {

        var modalInstance = $uibModal.open({
          animation: true,
          templateUrl: 'components/home/homeSelectSocialMedia.html',
          controller: 'ModalHomeInstanceCtrl'
        });

    };


    // Menu
    $scope.tab = 1;
    $scope.isSet = function (tabId) {
      return $scope.tab === tabId;
    };
    $scope.setTab = function (tabId) {
      $scope.tab = tabId;
    };


  })


// Controller for Modal
.controller('ModalHomeInstanceCtrl', function ($scope, $uibModalInstance, $window, ENV, Restangular, localStorageService, $analytics) {

    var user_id = localStorageService.get('user_elokenz_userid');
    // The base URL for all articles;
    var baseAuthor = Restangular.one('users',user_id);

    baseAuthor.get().then(function(user) {
      $scope.user = user;
    });


  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
    };


    $scope.addSocialAccount = function(accountName){
        $scope.twitterConnection = ENV.userUrl + '/accounts/twitter/login/?process=connect';
        $scope.facebookConnection = ENV.userUrl + '/accounts/facebook/login/?process=connect';

        if (accountName === 'twitter') {
            $analytics.eventTrack('Connect Social Account', {platform: 'Twitter'});
            $window.location.href = $scope.twitterConnection;
            return;
        }
        if (accountName === 'facebook') {
            $analytics.eventTrack('Connect Social Account', {platform: 'Facebook'});
            $window.location.href = $scope.facebookConnection;
            return;
        }
    };

    $scope.update_twitter_follow = function() {
        //base_author.put(endpoint_author, $scope.author);
        Restangular.one('users', $scope.user.elokenz_userid).patch(
            {
                'twitter_follow_us': $scope.user.twitter_follow_us
            }
            );
        };

});
