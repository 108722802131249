/*jshint unused:true, eqnull:true */
/*jshint unused: vars */

/* see http://stackoverflow.com/a/22037302 */

angular.module('dashboardApp')
    /**
     * @ngdoc function
     * @name dashboardApp.directive:ngFileSelect
     * @description
     * # This service should handle the upload.
     */
    .directive('ngFileSelect', function () {
        'use strict';

        return {
            link: function ($scope, el) {

                el.bind('change', function (e) {
                    $scope.file = (e.srcElement || e.target).files[0];
                    $scope.getFile();
                });
            }
        };
    })

    /**
     * @ngdoc function
     * @name dashboardApp.directive:fileDropzone
     * @description
     * # This service should handle the parsing of the csv file.
     */
    .directive('fileDropzone', function () {
        'use strict';

        return {
            restrict: 'A',

            scope: {
                file: '=',
                fileName: '='
            },

            link: function (scope, element, attrs) {

                var processDragOverOrEnter = function (event) {
                    if (event !== null) {
                        event.preventDefault();
                    }
                    if (event.dataTransfer) {
                        event.dataTransfer.effectAllowed = 'copyMove';
                    }
                    else if (event.originalEvent.dataTransfer){
                        event.originalEvent.dataTransfer.effectAllowed = 'copyMove';
                    }
                    console.log(event);
                    return false;
                };

                element.bind('dragover', processDragOverOrEnter);
                element.bind('dragenter', processDragOverOrEnter);

                return element.bind('drop', function (event) {

                    if (event !== null) {
                        event.preventDefault();
                    }

                    scope.$parent.file = event.dataTransfer ? event.dataTransfer.files[0] : event.originalEvent.dataTransfer.files[0];
                    scope.$parent.getFile();
                });
            }
        };
    });
