'use strict';

/**
 * @ngdoc function
 * @name dashboardApp.controller:ArticleCtrl
 * @description
 * # ArticleCtrl
 * Controller of the dashboardApp - Articles
 */
angular.module('dashboardApp')
  .controller('ArticleCtrl', function($scope,  Restangular, localStorageService, $q, growl, $interval, $analytics, $uibModal, $location, $routeParams, repostService, Utils, spinnerService, onboardingService, $log) {


// Import social account count and pops up if no available accounts
Utils.init().then(function () {
  $scope.socialAccounts = Utils.getData().socialAccounts;
});

$scope.popUpNoSocialAccount = function (size) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'components/socialAccountSelect/partials/modalSocialAccountEmpty.html',
        controller: 'ModalInstanceCtrlArticles',
        size: size
      });
    };

// Functions
    // Posting single url
    $scope.postArticle=function(articleUrl){
      $scope.isPostingArticle = true;
        var article = {'url': articleUrl};
        var correctUrl = repostService.checkUrl(articleUrl);
        if (correctUrl){
          // Add the article to Elokenz (it's not associated to the user yet)
          Restangular.all('pages').post(article).then(function(postedArticle) {
              $analytics.eventTrack('Added Article');
              var i = $scope.articles.length;
              var articleAbsent = true;
              while (i-- && articleAbsent) {
                if ($scope.articles[i].id === postedArticle.id) {articleAbsent = false;}
              }
              if (articleAbsent){
                // temporarily add the article, even if the author is not yet associated to it
                $scope.articles.unshift(postedArticle); // Might contain one duplicate

                // Associate the user and the article
                // Just requires an empty POST to the /users/[userid]/articles/[articleid]/tags
                Restangular.one('users',user_id).one('articles', postedArticle.id).all('tags').post({}).then(function(tags) {
                    // Add the new article to the scope for other treatments

                    $scope.articles[$scope.articles.indexOf(postedArticle)].tags = tags;
                    $log.debug('Adding this step to onboarding - 1');
                    onboardingService.addSuccessStep('newArticle'); // Add a new successful step to onboarding if necessary
                });

                // If the article is new, let's loop to get the info when they're available
                if (postedArticle.http_status !== 200){
                  console.log('Going to the fetching interval');
                  $scope.newArticleFetching(postedArticle);
                }

              }
            $scope.isPostingArticle = false ;
          }, function(response){
            console.log(response);
            growl.error('Something wrong happened when you tried to add this article');
            $scope.isPostingArticle = false ;
          });
        }
    }; // end posting


    // Creating Repost
    $scope.createRepost = function (article) {
    baseAuthor.all('pots').post(
            {'article': article.id
            }
        ).then(function(repost) {
            $analytics.eventTrack('Added Repost');
            $location.path("/repost/"+repost.id);
            onboardingService.addSuccessStep('newRepost'); // Add a new successful step to onboarding if necessary
        }, function(response){
            console.log(response);
            if (response.non_field_errors != "undefined") {
                console.log("Non field error");
                console.log(response);
                growl.error("The same Repost already exists", {ttl: 5000});
            } else {
                console.log("Other error");
            };
        }); // End get articles);
    };
    // End Creating Repost


    // Create Repost from Article
    $scope.createRepostSuggested = function (articleId) {
      $scope.isCreatingSuggestedRepost = true;
      console.log("Creating a repost from suggestion");
      // Associate the user and the article
      // Just requires an empty POST to the /users/[userid]/articles/[articleid]/tags
      baseAuthor.one("articles", articleId).all("tags").post({}).then(function(meaningLessTag) {
        // as copied from repostController.js :
        baseAuthor.all('pots').post(
            {'article': articleId
            }
        ).then(function(repost) {
            $analytics.eventTrack('Added Repost');
            $scope.fetchingArticle = false;
            onboardingService.addSuccessStep('newRepost'); // Add a new successful step to onboarding if necessary
            $location.path("/repost/"+repost.id);
        }, function(response){
            console.log(response);
            $scope.isCreatingSuggestedRepost = false;
            if (response.non_field_errors != "undefined") {
                console.log("Non field error");
                console.log(response);
                growl.error("The same Repost already exists", {ttl: 5000});
            } else {
                console.log("Other error");
                growl.error("Something wrong happened - Try to send us a message to tell us about this", {ttl: 5000});
            };
        }); // End get articles);
      }, function(response){
        $scope.isCreatingSuggestedRepost = false;
        $scope.showRepost = false;
        // The article doesn't exist probably
        growl.error("Something wrong happened - Try to send us a message to tell us about this (error message : #tag_404)", {ttl: 10000});
      });
    };

    // End create Repost

  // POST new tag to the API
  $scope.tagAdded = function($tag, article){
    console.log(article.id);
    console.log($tag);
    $scope.all_tags.push($tag);
    Restangular.one("users",user_id).one("articles", article.id).all("tags").post($tag).then(function(posted_tag) {
                console.log("Tag successfully posted");
                $analytics.eventTrack('Added Tag', {targetObject: 'article'});
                article.tags = posted_tag;
            });
  }; // end posting tag


  // POST new source to the API
  $scope.addSource = function(source){
    $scope.spinnerAddSourceLibraryIsLoading= true;
    source.enabled = true;
    source.source_type="R";
    baseAuthor.all("sources").post(source).then(function(posted_source) {
                console.log("Source successfully posted");
                $analytics.eventTrack('Added Suggestion Source');
                $scope.suggestedSources.unshift(posted_source);
                $scope.spinnerAddSourceLibraryIsLoading= false;
            }, function(response){
        growl.error('We couldn\'t add your source. Are you sure that this source does not already exist?', {ttl: 5000});
        spinnerService.hide('spinnerAddSourceLibrary');
    }); // End DELETE);
  }; // end posting source

  // Rescan source
  $scope.rescanSource = function(source){
    source.rescanningSpinner = true;
    baseAuthor.one("sources", source.id).all("rescan").get([]).then(function(result) {
        console.log("Source successfully rescaned");
        $analytics.eventTrack('Rescan Suggestion Source');
        growl.success("Source successfully rescaned");
        source.rescanningSpinner = false;
    });
  }; // end posting source


  // Remove an Article from the API
  $scope.deleteArticle = function(article){
    console.log(article.id);
    Restangular.one("users",user_id).one("articles", article.id).remove().then(function(){
      $analytics.eventTrack('Deleted Article');
      $scope.articles.splice($scope.articles.indexOf(article), 1);
      console.log("Successfully removed");
    });
  }; // end remove article

  // Update a Source
  $scope.updateSource = function(source){
    baseAuthor.one("sources", source.id).patch(
        {
        'enabled': source.enabled
        }
    );
  }; // end update a source

  // Delete a suggestion
  $scope.deleteSuggestion = function(article){
      baseAuthor.one("suggested", article.suggestionId).remove().then(function(){
      $analytics.eventTrack('Deleted Suggestion');
      $scope.suggestedArticles.splice($scope.suggestedArticles.indexOf(article), 1);
    });
  };
  $scope.deleteSuggestionById = function(suggestionId){
      baseAuthor.one("suggested", suggestionId).remove().then(function(){
      $analytics.eventTrack('Deleted Suggestion');
      $scope.showDismissRepost = false;
    }, function(response){
        growl.error("Something wrong happened - The suggestion you tried to remove doesn't exist anymore)", {ttl: 5000});
        $scope.showDismissRepost = false;
        console.log(response);
    }); // End DELETE
;
  }; // end delete suggestion

  // Delete a suggestion
  $scope.deleteSource = function(source){
      source.deletingSpinner = true;
      baseAuthor.one("sources", source.id).remove().then(function(){
      $analytics.eventTrack('Deleted Suggestion Source');
      $scope.suggestedSources.splice($scope.suggestedSources.indexOf(source), 1);
      source.deletingSpinner = false;
    });
  }; // end delete suggestion


  // DELETE tag from the API
  $scope.checkTagRemoval = function($tag){
    if($tag.auto_generated) {
      growl.warning("Can't delete auto generated tags (they usually start with ' prefix: ' )");
    }
    return !($tag.auto_generated);
  };

  $scope.tagRemoved = function($tag, $article_id){

    if($tag.auto_generated  === false){
      Restangular.one("tags", $tag.id).remove().then(function() {
                  $analytics.eventTrack('Deleted tag', { targetObject: 'article'});
                  console.log("Tag successfully removed");

              });
    } else{
      growl.warning("Can't delete auto generated tags");
      console.log("Can't delete auto generated tags");
    }
  }; // end deleting tag



  // Opening Modal
  ////////////////
  $scope.warningLibraryFull = function (size) {

    var modalInstance = $uibModal.open({
      animation: true,
      templateUrl: 'components/articles/modal.html',
      controller: 'ModalInstanceCtrlArticles',
      size: size
    });
  };

var repostArticleId = $routeParams.articleCreateRepostId;
var dismissArticleId = $routeParams.articleDismissRepostId;
var user_id = localStorageService.get('user_elokenz_userid');
// The base URL for all articles;
var baseAuthor = Restangular.one("users",user_id);
var all_authors = Restangular.all('users');
$scope.showSpinnerSuggestion = true;
$scope.showSpinnerLibrary = true;
// Menu
$scope.tab = 1;
$scope.isSet = function (tabId) {
    return $scope.tab === tabId;
};
$scope.setTab = function (tabId) {
    $scope.tab = tabId;
    switch(tabId){
      default:
        break;
    }
};

var tabSearch = ($location.search()).tab;  // we can redirect to social tab directly
if (tabSearch === 'suggested') {
  $scope.setTab(2);
}
if (tabSearch === 'source') {
  $scope.setTab(3);
}
// End Menu

if (repostArticleId > 0){
  $scope.showRepost = true;
  $scope.setTab(2);
  $scope.createRepostSuggested(repostArticleId);
}

if (dismissArticleId > 0){
  $scope.showDismissRepost = true;
  $scope.setTab(2);
  $scope.deleteSuggestionById(dismissArticleId);
}

$scope.tags = [{ text: 'Tag1' },{ text: 'Tag2' },{ text: 'Tag3' }];



// Get suggested  (only if we are not removing a suggestion beforehand{
var stopDismissed;
stopDismissed = $interval(function() {
  if ($scope.showDismissRepost) {

  } else { // get suggested articles
    baseAuthor.all("suggested").getList().then(function(suggestedItems) {
        $scope.suggestedArticles = [];
        for (var i = suggestedItems.length - 1; i >= 0; i--) {
          var tmpArticle = suggestedItems[i].article;
          tmpArticle.discoveryDate = suggestedItems[i].created_at;
          tmpArticle.suggestionId = suggestedItems[i].id;
          tmpArticle.source = suggestedItems[i].source.source_type;
          $scope.suggestedArticles.push(tmpArticle);
        }
        $scope.showSpinnerSuggestion = false;
      }, function(response){
          console.log(response);
    });
    $scope.stopArticleDismissing();
    // End get articles
  }
}, 500,30);


$scope.stopArticleDismissing = function() {
  if (angular.isDefined(stopDismissed)) {
    $interval.cancel(stopDismissed);
    stopDismissed = undefined;
  }
};

$scope.$on('$destroy', function() {
  // Make sure that the interval is destroyed too
  $scope.stopArticleDismissing();
});

// Get articles {
baseAuthor.all("articles").getList().then(function(articles) {
    $scope.articles = articles;
    $scope.showSpinnerLibrary=false;
    // Retrieve tags
    // angular.forEach(articles , function(article, key) {
    //     Restangular.one("users",user_id).one("articles",article.id).all("tags").getList().then(function(tags) {
    //         $scope.articles[key].tags = tags
    //     });
    // });
}, function(response){
    console.log(response);
}); // End get articles



// Get sources {
baseAuthor.all("sources").getList().then(function(suggestedSources) {
    $scope.suggestedSources = suggestedSources;
  }, function(response){
      console.log(response);
}); // End get articles

  // Autocomplete
  ///////////////
  // Get tags {
   Restangular.one("users",user_id).all("tags").getList().then(function(tags) {
        $scope.all_tags = tags
    });
    // End get tags




   $scope.loadTags = function() {
            var deferred = $q.defer();
            deferred.resolve($scope.all_tags);
            return deferred.promise;
        }
  ///////////////////
  // End autocomplete

  // Syncronized new article

  var stop;
  $scope.newArticleFetching = function(article) {
    // Don't start a new fetch if we are already fetching
    if ( angular.isDefined(stop) ) return;
    console.log("entered the interval");
    stop = $interval(function() {
      Restangular.one("users",user_id).one("articles",article.id).get().then(function(newArticle) {
        if (newArticle.http_status === 200){
          $scope.articles[$scope.articles.indexOf(article)] = newArticle;
          $scope.articles[$scope.articles.indexOf(newArticle)].tags = newArticle.tags_list;
          $scope.stopNewArticleFetching();
          onboardingService.addSuccessStep('newArticle'); // Add a new successful step to onboarding if necessary
        }
      });
    }, 3000, 60); // do it every 2 seconds,  30 times max
  };


  $scope.stopNewArticleFetching = function() {
    if (angular.isDefined(stop)) {
      $interval.cancel(stop);
      stop = undefined;
    }
  };

  $scope.$on('$destroy', function() {
    // Make sure that the interval is destroyed too
    $scope.stopNewArticleFetching();
  });


  // End syncronized new article

})

.controller('ModalInstanceCtrlArticles', function ($scope, $uibModalInstance, $analytics, $location) {

  $scope.cancel = function () {
    $analytics.eventTrack('Not interested in Upgrade');
    $uibModalInstance.dismiss('cancel');
  };

  $scope.sendOffer = function () {
    $analytics.eventTrack('Interested in Upgrade');
    $location.path("/upgrade");
    $uibModalInstance.dismiss('cancel');
  };
});
