'use strict';

var module = angular.module('dashboardApp');

module.service('calendarService', function(Restangular, localStorageService, $q, Utils, spinnerService, $log, $filter, $analytics, growl, $rootScope, onboardingService) {

  var self = this;

  // Service variables
  var  userId, baseAuthor, allSlots, socialAccountId, days, slots;

  days = [
      {'name': 'Monday', 'dayOfWeek':0},
      {'name': 'Tuesday', 'dayOfWeek':1},
      {'name': 'Wednesday', 'dayOfWeek':2},
      {'name': 'Thursday', 'dayOfWeek':3},
      {'name': 'Friday', 'dayOfWeek':4},
      {'name': 'Saturday', 'dayOfWeek':5},
      {'name': 'Sunday', 'dayOfWeek':6}
    ] ;

  // Return the data when getting the service object
  this.getData = function(){
    return {
      'days': days,
      'slots': slots,
    };
  };

  /////////////////////////////////////////
  /////////////////////////////////////////

  this.getSlots = function(){
    // Get slots
    var deferred = $q.defer();
    baseAuthor.all('slots').getList().then(function(result) {
      allSlots = result;
      deferred.resolve(result);
      }, function(response){
        $log.error(response);
      });
    // End get slots
    return deferred.promise;
  };

  this.setSocialAccount = function(socialAccountIdInput){
    socialAccountId = socialAccountIdInput;
  },

  this.getOneSlot = function(){
    slots = $filter('filter')(allSlots, { social_account: socialAccountId });
    return slots;
  };

  this.updateSlot = function(slot) {
    var deferred = $q.defer();
    slot.hour = slot.date.getHours();
    slot.minute = slot.date.getMinutes();

    var slotTime = {
            'hour': slot.hour,
            'minute': slot.minute
        };

    baseAuthor.one('slots', slot.id).patch(
      slotTime
        ).then(function() {
          $analytics.eventTrack('Updated Time Slot');
          $log.debug("time of slot updated");
          growl.success('Calendar successfully updated!');
          deferred.resolve();
        }, function(response){
          $log.error(response);
          deferred.reject(response);
        });
    return deferred.promise;
  };

  this.deleteSlot = function(slot) {
    var deferred = $q.defer();
    baseAuthor.one('slots', slot.id).remove().then(function() {
          allSlots.splice(allSlots.indexOf(slot), 1);
          self.getOneSlot(); // update the correct value
          $analytics.eventTrack('Deleted Time Slot');
          growl.success('Slot deleted!');
          deferred.resolve();
        }, function(response){
          $log.error(response);
          deferred.reject(response);
        });
    return deferred.promise;
  };

  this.updateUserOnboardingAccount = function(){
    // Save the action for onboarding
    baseAuthor.one('actions').patch(
        {
            'adjusted_calendar_slot': true
        }
    );
    onboardingService.addSuccessStep('modifyCalendar'); // Add a new successful step to onboarding if necessary
    try {
      if (!$rootScope.user.actions.adjusted_calendar_slot){
        $rootScope.user.actions.adjusted_calendar_slot = true;
        $rootScope.user.actions.overall_progress += 25; // FIXME : this value depends on the number of onboarding actions available
      }
    }
    catch(err) {
    $log.error(err.message);
    }
  };

  this.addSlot = function(day) {
    var deferred = $q.defer();

    var slotToAdd = {
            'day_of_week': day,
            'hour': 0,
            'minute': 0,
            'social_account': socialAccountId
        };
    baseAuthor.all('slots').post(
      slotToAdd
        ).then(function(result) {
          $analytics.eventTrack('Added Time Slot');
          allSlots.push(result);
          self.getOneSlot(); // update the correct value
          deferred.resolve(result);
        }, function(response){
          console.log(response);
          deferred.reject(response);
        });

    return deferred.promise;
    };


  this.init = function(socialAccountIdInput){

    userId = localStorageService.get('user_elokenz_userid');
    // The base URL for all articles;
    baseAuthor = Restangular.one('users',userId);
    socialAccountId = socialAccountIdInput;

    var deferred = $q.defer();

    self.getSlots().then(function (result){
      deferred.resolve(self.getOneSlot());
    });
    return deferred.promise;

  };


});
