angular.module('dashboardApp')
.directive('onboardingTour', function(onboardingService){
      return {
        restrict: 'A',
        transclude: true,
        scope: {},
        templateUrl: 'shared/tourJoyride/tourOnboarding.html',
        controller: function($scope, onboardingService, $route){

            var getScope = function(){
              $scope.doingTour = onboardingService.getData().doingTour;
              $scope.step = onboardingService.getData().tourStep;
            };

            onboardingService.init();
            getScope();
            $scope.showingOnPage = true;

            $scope.startTour = function(){
              onboardingService.startTour();
              getScope();
            };

            $scope.stopTour = function(){
              onboardingService.stopTour();
              $scope.doingTour = onboardingService.getData().doingTour;
              $scope.step = onboardingService.getData().tourStep;
            };

            $scope.$on('newSuccessStep', function() {
              getScope();
            });

            $scope.$on('login', function() {
              onboardingService.init();
              getScope();
            });
            $scope.$on('logout', function() {
              onboardingService.reset();
              getScope();
            });

        },
        link: function($scope, $element, $attrs) {
            $scope.$on("$routeChangeSuccess", function (event, current, previous) {
              if (current.$$route.controller ==='RepostDetailCtrl'){
                $scope.showingOnPage = false;
              } else {
                $scope.showingOnPage = true;
              }
            });
        }
      }
    });
