/**
 * @ngdoc function
 * @name dashboardApp.factory:Utils
 * @description
 * # This service keeps commonly used functions in one place.
 */
angular.module('dashboardApp')
    .service('onboardingService', ['localStorageService', '$q', 'Restangular', '$location', '$log', function (localStorageService,$q, Restangular, $location, $log) {
    	'use strict';

    	var self = this;
        // Service variables
        var userId, baseAuthor, doingTour, tourStep, user;


        // Return the data when getting the service object
        this.getData = function(){
            return {
              'doingTour': doingTour,
              'tourStep': tourStep,
            };
        };

        this.importData = function (){
            // if (localStorageService.get('doingTour')){
            //         doingTour = localStorageService.get('doingTour');
            // } else {
            //         doingTour = false;
            // }
            if (localStorageService.get('tourStep')){
                    tourStep = localStorageService.get('tourStep');
            }
            if (localStorageService.get('user')){
                    user = localStorageService.get('user');

                    if (!angular.isUndefined(user.actions)){
                        doingTour = !user.actions.global_tour_done;
                    } else {
                        doingTour = false; // if not set, we prefer to hide the Tour
                    }
            }

            if (localStorageService.get('user_elokenz_userid')){
                userId = localStorageService.get('user_elokenz_userid');
                baseAuthor = Restangular.one('users',userId);
            }
        };

        this.saveData = function () {
            localStorageService.set('tourStep', tourStep);
            return null;
        };

        this.startTour = function() {
            // Let's reimport the data since this might have changed if the user tried it by himself
            self.importData();
            self.computeStep();
            doingTour = true;
            self.saveData();
            var patchDict = {'global_tour_done':false};
            if (tourStep === 0){
                patchDict['onboarding_step_reached'] = 1;
                user.actions.onboarding_step_reached = 1;
                self.computeStep();
            }
            baseAuthor.one('actions').patch(patchDict).then(function (){
                self.importData();
                user.actions.global_tour_done = false;
                user.actions.onboarding_step_reached = 1;
                localStorageService.set('user', user);
            });
        };

        this.stopTour = function() {
            doingTour = false;
            self.saveData();
            baseAuthor.one('actions').patch({'global_tour_done':true}).then(function (){
                self.importData();
                user.actions.global_tour_done = true;
                localStorageService.set('user', user);
            });
        };

        this.sendSuccessEvent = function(){
            // send the signal back to the directive :
            //get the topmost scope
            var $scope = angular.element(document).scope();
            // new step event
            var event = 'newSuccessStep';
            var args = [];
            $scope.$broadcast(event, args);
        };

        var stepKeys = {
           'newArticle': {'name': 'added_first_article', 'step':1},
           'newSocialAccount': {'name':'connected_twitter_account', 'step':2},
           'newRepost': {'name':'enabled_first_repost_message', 'step':3},
           'repostDetailTourDone': {'name':'tour_repost_messages_done', 'step':4},
           'modifyCalendar': {'name':'adjusted_calendar_slot', 'step':5},
        };

        this.addSuccessStep = function (stepName) {
           // helper to sync the dashboard with the API when a new onboarding step occurs
           self.importData();
           var actions = user.actions;
           var stepKey = stepKeys[stepName].name;
           var stepCount = stepKeys[stepName].step;

           if (angular.isUndefined(stepKey) || actions[stepKey]) {return; }

           $log.debug('addSuccessStep ', stepName);
           var patchDict = {};
           patchDict[stepKey] = true;
           patchDict['onboarding_step_reached'] = stepCount;
           baseAuthor.one('actions').patch(patchDict).then(function () {
                $log.debug('addSuccessStep : rest api called');
                user.actions[stepKey] = true;
                localStorageService.set('user', user);

                self.computeStep();
                self.sendSuccessEvent();
           });
        };

        this.computeStep = function(){
            if (angular.isUndefinedOrNull(user)){
                tourStep = null;
                doingTour = null;
                self.saveData();
                return;
            }
            var actions = user.actions;
            $log.debug('Computestep, ', actions);
            // Step 0 : user onboarding_step reached is 0
            if (actions.onboarding_step_reached===0){
                tourStep = 0;
            }
            // Step 1 : has no article
            else if (!actions.added_first_article){
                tourStep = 1;
            }
            // Step 2 : has no social account
            else if (!actions.connected_twitter_account){
                tourStep = 2;
            }
            // Step 3 : has no repost
            else if (!actions.enabled_first_repost_message){
                tourStep = 3;
            }
            // Step 3 : has no calendar slot
            else if (!actions.tour_repost_messages_done){
                tourStep = 4;
            }
            // Step 4 : should follow tour on Repost Detail page
            else if (!actions.adjusted_calendar_slot){
                tourStep = 5;
            }
            else {
                tourStep = 6;
            }
            $log.debug('Computing onboarding step, ', tourStep);
            self.saveData();

            // Send the information to GTM
            var dataLayer = window.dataLayer = window.dataLayer || [];
            dataLayer.push({
                "tourStep": tourStep
                });
        };

        this.init = function(){
            self.importData();
            self.computeStep();
          };

        this.reset = function(){
            tourStep = doingTour = userId = user = baseAuthor = null;
        };


    }]);
