'use strict';

/**
 * @ngdoc function
 * @name dashboardApp.controller:ArticleCtrl
 * @description
 * # ArticleCtrl
 * Controller of the dashboardApp - Articles
 */
angular.module('dashboardApp')
  .controller('DeckCtrl', function($scope,  Restangular, localStorageService, $analytics) {
    console.log("Jice");
    var user_id = localStorageService.get('user_elokenz_userid');
    
    //$scope.decks =  [{"id":2,"name":"Mesyugftyufytles","description":"","tags_list":"[u'chat']","user":3}];
    
    // The base URL for all articles;  
    var base_author = Restangular.one("users",user_id);  
    // Get decks {
    base_author.all("decks").getList().then(function(decks) {
        $scope.decks = decks;
        // Retrieve info for each deck
        angular.forEach(decks , function(deck, key) {

            var deckRoute = base_author.one("decks", deck.id);
            //console.log(typeof(JSON.parse(deck.tags_list)));
            //deck.tags_list = JSON.parse(deck.tags_list); // Don't know why I can't get the object from the API, instead it gets a string
            deckRoute.get().then(function(data) {
                $scope.decks[key].info = data;
            });


            // get mention count for last 30 days
            deckRoute.all("metrics").customGET("mentiondates").then(function(data) {
                // $scope.decks[key].datesTwitter = data.datesTwitter;
                // $scope.decks[key].datesGoogle = data.datesGoogle;
                console.log(data.datesGoogle);
                $scope.decks[key].datesMention = getDatesMention(data.datesGoogle, data.datesTwitter);
            });

        });
    });
  
    // Remove a Deck
    $scope.deleteDeck = function(deck){
      console.log(deck.id);
      console.log("Deleting this deck");
      base_author.one("decks", deck.id).remove().then(function(){
        $analytics.eventTrack('Deleted deck');
        $scope.decks.splice($scope.decks.indexOf(deck), 1);
        console.log("Successfully removed");
      }); // end remove deck
    };






/******************** NVD3 *******************/
    $scope.options = {
    chart: {
        type: 'lineChart',
        interpolate: "cardinal",  
        //height: 450,
        width: 300,
        margin : {
            top: 20,
            right: 20,
            bottom: 60,
            left: 65
        },
        x: function(d){ return d.x; },
        y: function(d){ return d.y; },
        showValues: true,
        valueFormat: function(d){
            return d3.format(',.02f')(d);
        },
        transitionDuration: 500,
        xAxis: {
            axisLabel: 'Date',
            showMaxMin: false,
            tickFormat: function(d) {
                        return d3.time.format('%d %b %Y')(new Date(d));
                    }
        },
        yAxis: {
            axisLabel: 'Mention count',
            rotateYLabel: false,
            
            tickFormat: function(d){
                        return d3.format(',.0d')(d);
                    } ,
            //axisLabelDistance: 50,
        },
    }
};

function getDatesMention(listDatesGoogle, listDatesTwitter){
    var datesTwitter = [], datesGoogle = [];

    console.log("Arguments :");
    console.log(listDatesTwitter.length);
    console.log(listDatesGoogle.length);

    for (var i = 0; i < listDatesTwitter.length; i++) {
                var dateFormat = listDatesTwitter[i]["published"].replace(/-/g, "/");
                var ts = new Date(dateFormat).getTime()
                datesGoogle.push({x: ts, y: listDatesGoogle[i]["count"]});
                datesTwitter.push({x: ts, y: listDatesTwitter[i]["count"]});
                //datesTwitter.push({x: listDatesTwitter[i]["published"].replace(/-/g, "/"), y: listDatesTwitter[i]["count"]});
            }

    return [
                {
                    values: datesGoogle,      //values - represents the array of {x,y} data points
                    key: 'G+', //key  - the name of the series.
                    color: '#dd4b39',  //color - optional: choose your own line color.
                    area: true 
                }
                ,
                {
                    values: datesTwitter,      //values - represents the array of {x,y} data points
                    key: 'Twitter', //key  - the name of the series.
                    color: '#00aced',  //color - optional: choose your own line color.
                    area: true 
                }
            ];

}

/******************** END NVD3 ************************/





});