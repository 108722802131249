'use strict';

angular.module('dashboardApp')
  // Controller for Single Repost
  .controller('RepostDetailCtrl', function ($scope, growl, Restangular, $routeParams, localStorageService, $analytics, $rootScope, $filter, Utils, $location, $anchorScroll, $window, $timeout, Tour, messageService, angularGridInstance, spinnerService, repostService, onboardingService, $log, $uibModal) {
    var user_id = localStorageService.get('user_elokenz_userid');
    var user = localStorageService.get('user');

    if(!isNaN($routeParams.repostId)) {
      var repostId = $routeParams.repostId ;
    }
    // The base URL for all articles;
    var baseAuthor = Restangular.one('users',user_id);

    $scope.enabledMessageCount = 0;

    $scope.iAmDoneEditing = true; // if iAmDoneEditing is True, then the button is working
    $scope.minDate = new Date();
    $scope.now = new Date();
    $scope.angularGridOptions = {
      gridWidth : 300,
      gutterSize : 10,
      refreshOnImgLoad : false
    };
    $scope.availableMessageLength = 250;
    $scope.maxLength = 250;
    $scope.newMessage = "";
    $scope.messageLength = 250;
    $scope.images = [];
    $scope.maxImages = 1;
    var usingImageInMessage = false;



    // Init :
    // Get  socialAccounts
    Utils.init().then(function () {
      $scope.social_accounts = Utils.getData().socialAccounts;
      $scope.active_accounts = Utils.getData().activeAccounts;
    }, function (error) {
      // promise rejected, could log the error with: console.log('error', error);
      console.log('error' , error);
    });

    repostService.init();


    $scope.isMessageEnabled = function (message) {
      return messageService.isMessageEnabled(message);
    };

    $scope.doneEditingRepost = function () {
      $location.path('repost');
    };

    // Refresh queue
    function refreshQueue() {
      var activeAccountID = Utils.getActiveSocialAccountId();
      // Calling getQueue will refresh the cache of repostService
      repostService.getQueue(activeAccountID);
      repostService.getPots(activeAccountID);
      repostService.sendRepostRefeshEvent();
    }

    // Count enabled messages
    function countEnabledMessages() {
      var enabledMessages = $filter('filter')($scope.repost.messages, function (d) {return $scope.isMessageEnabled(d);});
        if(enabledMessages !== undefined){
          $scope.enabledMessageCount = enabledMessages.length;
        } else {
          $scope.enabledMessageCount = 0;
        }
    }

    // Refresh message list
    function refreshMessageList() {
      messageService.getMessages().then(function () {
        var messages = messageService.getData().messages;
        $scope.repost.messages = messages;
        countEnabledMessages();
      });
    }

    // When the Queue is refreshed, we count how many messages are enabled
    $scope.$on('repostRefreshed', function(event, args) {
        countEnabledMessages();
        $log.info("In event repostRefreshed");
    });



    $scope.$watch('repost.expDate', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        $scope.repost.patch({
          'expiration_date': $scope.repost.expDate
        }).then( function(){
          refreshQueue();
        });
      }
    });

    // Update remaning char count
    $scope.$watch('newMessage', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        if ($scope.newMessage===undefined || $scope.newMessage === null){
          $scope.availableMessageLength = 250;
          $scope.maxLength = 250;
        } else {
            $scope.availableMessageLength = 250 - $scope.newMessage.length;
            $scope.maxLength = 250;
        }
      }
    });


    // Watch repost.is_enabled
    $scope.$watch('repost.enabled', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        $scope.repost.patch({
          'enabled': $scope.repost.enabled,
        }).then( function(){
          refreshQueue();
        });
      }
    });



    // Get current pot {
    messageService.init().then(function(){
      $scope.repost = messageService.getData().repost;

      // Get messages {
      messageService.getMessages().then(function () {
        var messages = messageService.getData().messages;

        $scope.repost.messages = messages;
        $scope.enabledMessageCount = $filter('filter')($scope.repost.messages, function (d) {return $scope.isMessageEnabled(d)}).length;
        $scope.listedImages = messageService.getData().listedImages;

        // We start the tour if the onboarding is not done.
        if (user.actions.tour_repost_messages_done !== true){
          $scope.startTour();
        };

        // Some watchers should be started after init to prevent "undefined" errors
        // Save expiration date
        $scope.$watch('repost.expirationDate2', function (newValue, oldValue) {
          if ($scope.repost.shouldExpire===true && $scope.repost.expirationDate2 !== undefined) {
            $scope.repost.expDate = $scope.repost.expirationDate2.toISOString();
          } else {
            $scope.repost.expDate = null;
          }
        }, true);
      });


    });

    // Uploading Images
    $scope.uploadImage = function () {
      uploadcare.openDialog(null, {
        publicKey: "9813ad12b4e36a38d775",
        imageShrink: "640x480",
        crop: "disabled",
        doNotStore: false,
        previewStep: false,
        imagesOnly: true
      }).progress(function(tabName) {
        spinnerService.show('spinnerImageUpload');
      }).fail(function(result) {
        spinnerService.hide('spinnerImageUpload');
        $scope.$apply();
      }).done(function (file) {
        file.promise().done(function (fileInfo) {
          var imageTmpUrl = fileInfo.cdnUrl;
          addImageToDjango(imageTmpUrl);
          spinnerService.hide('spinnerImageUpload');
          $scope.$apply();
        });
      });
    }

    function addImageToDjango(url) {
      // Later we should save images via Django
      if ($scope.images.length < $scope.maxImages){
        $scope.images.push(url);
      } else{
        $scope.images.splice(0,1,url);
      }
        messageService.addSuggestedImage(url, true);
        $scope.listedImages = messageService.getData().listedImages;
        usingImageInMessage = true;
    }


    $scope.clearImages = function () {
      $scope.images = [];
      usingImageInMessage = false;
      for (var i = $scope.listedImages.length - 1; i >= 0; i--) {
        $scope.listedImages[i].inUse = false;
      }
    };

    $scope.enableSuggestedImage = function(imageSuggested){
      for (var i = $scope.listedImages.length - 1; i >= 0; i--) {
        $scope.listedImages[i].inUse = false;
      }
      imageSuggested.inUse = true;
      addImageToDjango(imageSuggested.src);
    };

    // Save repost settings
    $scope.save_repost_settings = function () {
      if ($scope.repost.shouldExpire===true && $scope.repost.expirationDate2 !== undefined){
        var expDate = $scope.repost.expirationDate2.toISOString();
      } else {
        var expDate = null;
      }
      $scope.repost.patch({
        'boost': $scope.repost.boost,
        'is_evergreen': $scope.repost.is_evergreen,
        'enabled': $scope.repost.enabled,
        'expiration_date': expDate,
      });
    }

    // Save evergreen or regular
    $scope.set_evergreen = function (flag){
      $scope.repost.is_evergreen = flag;
      $scope.repost.patch({
        'is_evergreen': $scope.repost.is_evergreen
      }).then( function(){
          refreshQueue();
        });
    };

    // Save boost or regular
    $scope.set_boost = function (flag){
      if (!flag) {
        $scope.repost.boosted = flag;
        $scope.repost.patch({
          'boost': $scope.repost.boosted | 0 // to force int type for bool
        }).then( function(){
            refreshQueue();
          });
        return;
      }
      if (user.account.name=="T2") {
        $scope.repost.boosted = flag;
        $scope.repost.patch({
          'boost': $scope.repost.boosted | 0 // to force int type for bool
        }).then( function(){
            refreshQueue();
          }, function (response) {
            $log.error('Error while boosting post : ', response);
            if (response.status === 403) {
              $scope.repost.boosted = false;
              $scope.warningCantBoostPost();
            }
          });
      } else {
        $scope.repost.boosted = false;
        $scope.warningCantBoostPost();
      }

    };

    $scope.warningCantBoostPost = function (size) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'components/repost/partials/modalSocialAccountLimit.html',
        controller: 'ModalInstanceCtrlArticles',
        size: size
      });
    };

    // Add/Disable a Single Social Account for a Message
    $scope.switch_social_account = function (message,social_account) {
      message.showSpinnerSwitchAccount = true;
      if (social_account.enabledOnMessage == undefined){
        // the message doesn't exist for this social network. We need to create it
        // we copy the master message
        var postedMessage = angular.copy(message);
        // we set the alternate version and enable it
        // the similar version is set only if there is already a main message (always true in production)

        postedMessage.similar_to = postedMessage.id;
        postedMessage.social_account = social_account.id;
        postedMessage.enabled = true;
        // we delete the original id
        delete postedMessage.id;

        $scope.repost.all('messages').post(
          postedMessage
        ).then(function (newPostedMessage) {
          social_account.enabledOnMessage = true;
          social_account.messageId = newPostedMessage.id;
          message.showSpinnerSwitchAccount = false;
          message.enabled = true;
          $scope.enabledMessageCount = $filter('filter')($scope.repost.messages, function (d) {return $scope.isMessageEnabled(d)}).length;
        }, function (response){
          if (response.non_field_errors != "undefined") {
            growl.error("The same message already exists. They have to be unique. Please change your text", {ttl: 5000});
          } else {
            console.log("Other error");
          };
        });
      } else {
        // the current message exist for the social account, let's switch it
        social_account.enabledOnMessage = !social_account.enabledOnMessage; //new state
        $scope.repost.one('messages', social_account.messageId).patch({
          'enabled': social_account.enabledOnMessage,
        }).then(function (postedMessage) {
          message.showSpinnerSwitchAccount = false;
          message.enabled = true;
          $scope.enabledMessageCount = $filter('filter')($scope.repost.messages, function (d) {return $scope.isMessageEnabled(d)}).length;
          refreshQueue();
        });
      }

      if (social_account.enabledOnMessage === false) {
        $analytics.eventTrack("Disable Message");
      } else {
        $analytics.eventTrack("Enable Message");
      };
    };

    // Update single Message
    $scope.update_message = function (message) {
      //base_author.put(endpoint_author, $scope.author);
      $scope.repost.one('messages', message.id).patch({
        'auto_generated': message.auto_generated,
        'enabled': message.enabled,
      }).then( function(){
          refreshQueue();
        });
    };

    // Post single Message
    $scope.postNewMessage = function (message, isUpdating, updatedMessage) {
      $scope.showSpinnerPostMessage = true;
      $scope.iAmDoneEditing = true;

      // Get active social accounts first
      var active_accounts = Utils.getData().activeAccounts;
      $log.debug('postNewMessage : ', active_accounts);

      if (angular.isUndefined(active_accounts)) {
        growl.error("You need to select at least one social account associated to this message", {ttl: 5000});
        return;
      }

      if ($scope.availableMessageLength < 0) {
        growl.error("You have too many characters. Please shorten your message", {ttl: 5000});
        return;
      }

      if (angular.isUndefined(message)) {
        growl.error("Hey, you need to type at least a message", {ttl: 5000});
        return;
      }

      if ((active_accounts.length) < 1) {
        growl.error("You need to select at least one social account associated to this message", {ttl: 5000});
        return;
      }

      var urlImage = [];
      urlImage[0] = null;
      urlImage[1] = null;
      urlImage[2] = null;
      urlImage[3] = null;
      if ($scope.images.length !== 0) {
        for (var i = $scope.images.length - 1; i >= 0; i--) {
          urlImage[i] = $scope.images[i];
        };
      }

      // Add {{url}} at the end if it is not present
      if (message.indexOf("{{url}}") == -1) {
        message = message + ' {{url}}';
      }

      // If we are updating a post, then we should erase the original one
      if (isUpdating) {
        $analytics.eventTrack('Edited message', {  targetObject: 'repost'});
        $scope.deleteMessage(updatedMessage);
        $scope.isEditing = false;
      };

      // Post message to first social network
      // We get the associated id, and then create alternative versions with the other social networks
      $scope.repost.all('messages').post({
        'content': message,
        'enabled': true,
        media1: urlImage[0],
        media2: urlImage[1],
        media3: urlImage[2],
        media4: urlImage[3],
        social_account: active_accounts[0]
      }).then(function (postedMessage) {
        postedMessage.media = [];
        postedMessage.media1 === null ? null : postedMessage.media.push(postedMessage.media1);
        postedMessage.media2 === null ? null : postedMessage.media.push(postedMessage.media2);
        postedMessage.media3 === null ? null : postedMessage.media.push(postedMessage.media3);
        postedMessage.media4 === null ? null : postedMessage.media.push(postedMessage.media4);

        var similarToIndex = postedMessage.id;

        $analytics.eventTrack('Added Message', {targetObject: 'repost'});
        refreshQueue();

        // Save the action for onboarding - Twitter synced
        baseAuthor.one('actions').patch({
          'enabled_first_repost_message': true
        });

        try {
          if (!$rootScope.user.actions.enabled_first_repost_message){
            $rootScope.user.actions.enabled_first_repost_message = true;
            $rootScope.user.actions.overall_progress += 25; // FIXME : this value depends on the number of onboarding actions available
          }
        }
        catch(err) {
        $log.error(err.message);
        }


        // We add the main social account to the posted message

        postedMessage.social_accounts = [];

        // We add all social accounts
        for (var i = $scope.social_accounts.length - 1; i >= 0; i--) {
          var tmpAccount = angular.copy($scope.social_accounts[i]);
          tmpAccount.enabledOnMessage = undefined;
          // If account is used, we enable it directly for the user to see it

          if (active_accounts.indexOf(tmpAccount.id) != -1) {
            tmpAccount.enabledOnMessage = true;
          }

          postedMessage.social_accounts.unshift(tmpAccount);
        };

        var postedMessageMainAccount = $filter('filter')(postedMessage.social_accounts, function (d) {return d.id === active_accounts[0];})[0];
        postedMessageMainAccount.enabledOnMessage = true
        postedMessageMainAccount.messageId = postedMessage.id; // we keep this for further updates

        $scope.repost.messages.unshift(postedMessage);
        $scope.cancelEdition();
        $scope.showSpinnerPostMessage = false;

        $scope.enabledMessageCount = $filter('filter')($scope.repost.messages, function (d) {return $scope.isMessageEnabled(d)}).length;

        // Posting Alternates
        // If only one social network, we return
        if (active_accounts.length == 1) {
          return null;
        };

        // Else, we iterate over the remaining social networks and create alternates
        for (var i = active_accounts.length - 1; i >= 1; i--) {
          $scope.repost.all('messages').post({
            'content': message,
            'enabled': true,
            media1: urlImage[0],
            media2: urlImage[1],
            media3: urlImage[2],
            media4: urlImage[3],
            social_account: active_accounts[i],
            similar_to: similarToIndex
          }).then(function (postedMessage) { // here we add remaining social accounts to the newly created message
            var postedMessageAccount = $filter('filter')($scope.repost.messages[0].social_accounts, function (d) {return d.id === postedMessage.social_account;})[0];
            postedMessageAccount.enabledOnMessage = true
            postedMessageAccount.messageId = postedMessage.id; // we keep this for further updates
            refreshQueue();
            $uibModalInstance.dismiss('cancel');
          }, function (response){
            if (response.non_field_errors != "undefined") {
              growl.error("The same message already exists. They have to be unique. Please change your text", {ttl: 5000});

              $scope.showSpinnerPostMessage = false;
            } else {
              console.log("Other error");
            };
          });
        };




      }, function (response){
        if (response.non_field_errors != "undefined") {
          growl.error("The same message already exists. They have to be unique. Please change your text", {ttl: 5000});

          $scope.showSpinnerPostMessage = false;
        } else {
          console.log("Other error");
        };
      });

    };

    $scope.cancelEdition = function () {
      $scope.newMessage = "";
      $scope.clearImages();
      $scope.isEditing = false;
      $scope.updatedMessage = null;
      $scope.iAmDoneEditing = true;
    }

    $scope.closeEditor = function(){
      $scope.showEditor=false;
    }
    $scope.openEditor = function(){
      $scope.showEditor=true;
    }

    // Open Modal Edition Message
    $scope.openEditionMessage = function (size) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'components/repost/modalMessageEditor.html',
        controller: 'RepostMessageEditCtrl',
        size: 'lg',
        resolve: {
          socialAccounts: function () {return $scope.social_accounts;},
          activeAccounts: function () {return $scope.active_accounts;},
          listedImages: function () {return $scope.listedImages;},
          newMessage: function () {return $scope.newMessage;},
          isEditing: function () {return $scope.isEditing;},
          repost: function () {return $scope.repost;},
          updatedMessage: function () {return $scope.updatedMessage;},
        }
      });
      modalInstance.result.then(function (returnedItem) {
        // The edit popup is closed, let's refresh data

        // $scope.returnedItem = returnedItem;
      }, function () {
        $log.info('Modal dismissed at: ' + new Date());
        refreshMessageList();
        $scope.cancelEdition();
        $log.info("Message has been posted and we returned to controller");
      });
    };

    // Refresh grid
    $scope.refreshAngularGrid = function(){
      if (angularGridInstance.messagesGrid) {
          angularGridInstance.messagesGrid.refresh();
      }
    }

   //
    // Edit single Message
    //
    $scope.editMessage = function(message) {
      // qq$scope.openEditor();
      $scope.isEditing = true;
      $scope.iAmDoneEditing = false;
      $scope.newMessage = message.content;
      $scope.images = message.media;

      for (var i = $scope.listedImages.length - 1; i >= 0; i--) {
        if ($scope.images.indexOf($scope.listedImages[i].src) > -1){
          $scope.listedImages[i].inUse=true;
        }
      }
      for (var i = message.social_accounts.length - 1; i >= 0; i--) {
        if (message.social_accounts[i].enabledOnMessage === true) {
          message.social_accounts[i].active_repost_message = true;
        } else {
          message.social_accounts[i].active_repost_message = false;
        }
      }
      $scope.social_accounts = message.social_accounts;
      // Set the current active accounts
      localStorageService.set("social_accounts", $scope.social_accounts);
      Utils.updateActiveAccounts().then(function (activeAccounts) {
        $scope.active_accounts = activeAccounts;
      });

      $scope.updatedMessage = message;

      $scope.openEditionMessage();

      // $location.hash('repostForm');
      // $anchorScroll();
      // // see http://stackoverflow.com/a/25597540
      // $timeout(function () {
      //   var element = $window.document.getElementById("addMessage");
      //   if (element) {element.focus();}
      // });
    }

    // Delete single Message
    $scope.deleteMessage = function (message) {
      // Track in analytics
      $analytics.eventTrack('Deleted message', {  targetObject: 'repost'});
      // remove all associated messages
      for (var i = message.social_accounts.length - 1; i >= 0; i--) {
        var msgId = message.social_accounts[i].messageId;
        if (msgId !== undefined) {
          $scope.repost.one('messages', msgId).remove().then(function () {
            $scope.enabledMessageCount = $filter('filter')($scope.repost.messages, function (d) {return $scope.isMessageEnabled(d)}).length;
            refreshQueue();
          });
        };
      };
      // remove the current message from scope
      $scope.repost.messages.splice(  $scope.repost.messages.indexOf(message), 1 );
    };

    // Date picking
    $scope.open1 = function () {
      $scope.popup1.opened = true;
    };

    $scope.popup1 = {
      opened: false
    };

    // JoyRide Tour
    $scope.startTour = function () {
      var configTour = Tour.getData().tourRepostDetail;
      var configTourMessage = Tour.getData().tourRepostDetailMessage;
      var tourRepostDetailEnd = Tour.getData().tourRepostDetailEnd;

      if ($scope.repost.messages.length>0) {
        $scope.configTour = configTour.concat(configTourMessage);
      } else {
        $scope.configTour = configTour;
      };

      $scope.configTour = configTour.concat(tourRepostDetailEnd);

      $scope.startJoyRide = true;
      $scope.openEditor();
      $scope.iAmDoneEditing = false;
    };

    $scope.onFinish = function () {
      Restangular.one('users', user_id).one('actions').patch({
        'tour_repost_messages_done': true
      });
      onboardingService.addSuccessStep('repostDetailTourDone'); // Add a new successful step to onboarding if necessary
      $rootScope.user.actions.tour_repost_messages_done = true;
      user.actions.tour_repost_messages_done = true;
      localStorageService.set('user', user);
      $scope.iAmDoneEditing = true;
      $scope.closeEditor();
    };

    // See http://stackoverflow.com/a/26042432/2550237 and note on Evernote 'angular rootscope'
    $rootScope.getMyCtrlScope = function() {
      return $scope;
    }
});
