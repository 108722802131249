'use strict';

/**
 * @ngdoc function
 * @name dashboardApp.controller:LoginCtrl
 * @description
 * # NotificationsCtrl
 * Controller of the dashboardApp
 */
angular.module('dashboardApp')
  .controller('NotificationsCtrl', function ($scope, Restangular, localStorageService, $rootScope, lazyLoad, ENV) {
    $scope.access = false;

    var user_id = localStorageService.get('user_elokenz_userid');
    Restangular.one("users", user_id).all("notifications").getList().then(function(result){
        $scope.notifications = result;
        console.log("Got notifications");
    }, function(response){
        console.log(response);
    }); // End get author    
 
    $scope.mark_read = function() {
             Restangular.one("users", user_id).all("notifications").oneUrl("mark_read", null).get().then(function(result){
                console.log("Marked notifications as read");
                $scope.notifications.forEach(function(notif) {
                    notif.unread = false;
                }); 
                $rootScope.user.total_unread = 0;
            }, function(response){
                console.log(response);
            }); // End get author    


        };


    $scope.loadMore = function(obj) {
        if (typeof obj !== "undefined"){
            lazyLoad(obj);
        }
   };



  });