/**
 * @ngdoc function
 * @name dashboardApp.controller:SettingsCtrl
 * @description
 * # SettingsCtrl
 */
angular.module('dashboardApp')
    .controller('SettingsCtrl', function ($scope, Restangular, localStorageService, $rootScope, $window, ENV, $filter, $log, $location, growl, spinnerService) {
        'use strict';

        $scope.access = false;
        $scope.spinnerGetInvoices= true;
        $scope.spinnerDownloadInvoice= null;
        $scope.weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

        // The base URL for all articles;
        var sessionRoute = ENV.userUrl + '/is_connected/';
        $scope.twitterConnection = ENV.userUrl + '/accounts/twitter/login/?process=connect';
        $scope.linkedInConnection = ENV.userUrl + '/accounts/linkedin/login/?process=connect';
        $scope.facebookConnection = ENV.userUrl + '/accounts/facebook/login/?process=connect';
        $scope.googleConnection = ENV.userUrl + '/accounts/google/login/?process=connect';
        $scope.revokeTwitterConnection = ENV.userUrl + '/accounts/connections/revoke/twitter';
        $scope.revokeLinkedinConnection = ENV.userUrl + '/accounts/connections/revoke/linkedin';
        $scope.revokeFacebookConnection = ENV.userUrl + '/accounts/connections/revoke/facebook';
        $scope.revokeGoogleConnection = ENV.userUrl + '/accounts/connections/revoke/google';
        $scope.changePassword = ENV.userUrl + '/accounts/password/change/';


        // When an error message is present, let's disply a growl message
        if ($location.search()["error"] === "account_already_exist"){
            growl.error("This account is already in use, either by you or by someone else");
        };

        Restangular
            .oneUrl('is_connected', sessionRoute)
            .withHttpConfig({withCredentials: true})
            .get()
            .then(
                function (result) {
                    $log.debug('Is connected ?');
                    var isConnected = result.connected;
                    $log.debug(isConnected);
                    if (isConnected === true) {
                        $log.debug('you are connected');
                    } else {
                        $log.warn('You are not connected to the main user database. You need to re-enter your login info');
                    }
                }
            );

        Restangular
            .one('users', 'me')
            .get()
            .then(
                function (result) {
                    $scope.access = true;
                    $rootScope.user = result;
                    $scope.user = result;

                    // Get social accounts
                    var user_elokenz_id = $scope.user.elokenz_userid;
                    Restangular
                        .one('users', user_elokenz_id)
                        .all('social_accounts')
                        .getList()
                        .then(
                            function (result_sa) {
                                $scope.social_accounts = result_sa;

                                result_sa[0].active_repost_message = true;
                                localStorageService.set("social_accounts", result_sa);
                            }, function (response) {
                                $log.error("Couldn't get the social accounts : ", response);
                            }); // End get author

                    // get invoices:
                    $scope.getInvoices(user_elokenz_id);

                }, function (response) {
                    $scope.access = false;
                    $log.error("Couldn't get information about the current user : ", response);
                }
            );  // End get author


        $scope.updateSocialAccount = function (id) {
            // See http://stackoverflow.com/a/24393892/2550237
            var single_account = $filter('filter')($scope.social_accounts, function (d) {return d.id === id; })[0];
            var timezone = single_account.timezone;
            var social_fatigue = single_account.social_fatigue;
            Restangular
                .one('users', $scope.user.elokenz_userid)
                .one("social_accounts", id)
                .patch(
                    {
                        'timezone': timezone,
                        'social_fatigue': social_fatigue
                    }
                ).then(growl.success("Settings updated"));
          };

        $scope.saveSettings = function () {
            // base_author.put(endpoint_author, $scope.author);
            Restangular
                .one('users', $scope.user.elokenz_userid)
                .patch(
                    {
                        'settings_email_newsletter': $scope.user.settings_email_newsletter,
                        'bubble_per_day_twitter': $scope.user.bubble_per_day_twitter,
                        'twitter_social_fatigue': $scope.user.twitter_social_fatigue,
                        'bubble_per_day_google': $scope.user.bubble_per_day_google,
                        'bubble_per_day_facebook': $scope.user.bubble_per_day_facebook,
                        'settings_email_notifications': JSON.stringify($scope.user.settings_email_notifications),
                        'settings_email_weeklyRepostStats': JSON.stringify($scope.user.settings_email_weeklyRepostStats),
                        'settings_email_newSuggestedContent': JSON.stringify($scope.user.settings_email_newSuggestedContent),
                        'settings_email_repostDailyDigest': JSON.stringify($scope.user.settings_email_repostDailyDigest),
                        'settings_email_repostQueueGotEmpty': JSON.stringify($scope.user.settings_email_repostQueueGotEmpty)
                    }
                ).then(growl.success("Settings updated"));
        };

        $scope.getInvoices = function (userElokenzId) {
            //Get invoices from chargebee
            $log.info('Fetching invoices for user', userElokenzId);
            Restangular
                .one('users', userElokenzId)
                .all('chargebee_invoices_list')
                .getList()
                .then(function (resultInvoices) {
                                $scope.invoices = resultInvoices;
                                $scope.spinnerGetInvoices= false;
                            }, function (response) {
                                $log.error('Couldn\'t get invoices: ', response);
                            }); // End get author
        };

        $scope.downloadInvoice = function (invoiceID) {
            //Get invoices from chargebee
            $scope.spinnerDownloadInvoice = true;
            $log.info('Download invoice for user', invoiceID);
            Restangular
                .one('users', invoiceID)
                .customGET('download_invoice')
                .then(function (resultInvoice) {
                                window.location.assign(resultInvoice.url);
                                $scope.spinnerDownloadInvoice = true;
                            }, function (response) {
                                $log.error('Couldn\'t get invoices: ', response);
                            }); // End get author
        };

        $scope.go_revoke_account = function (socialAccount) {
            $log.info("Revoking social account ", socialAccount.id);
            Restangular
            .one('users', $scope.user.elokenz_userid)
            .one('social_accounts', socialAccount.id)
            .remove()
            .then(
                function (result_sa) {
                    $log.info("Revoked social account : ");
                    switch(socialAccount.name) {
                        case "T":
                            $window.location.href = $scope.revokeTwitterConnection;
                            break;
                        case "F":
                            $window.location.href = $scope.revokeFacebookConnection;
                            break;
                        case "L":
                            $window.location.href = $scope.revokeLinkedinConnection;
                            break;
                        case "G":
                            $window.location.href = $scope.revokeGoogleConnection;
                            break;
                        default:
                            $window.location.reload();
                            break;
                    }

                }, function (response) {
                    growl.error("Account was not correctly deleted");
                    $log.error("Couldn't revoke social account : ", response);
                }
            ); // End revoke social account
        };

        $scope.mimickUserSupportStaff = function (userid) {
            $scope.user.elokenz_userid = userid;
            var user_elokenz_id = $scope.user.elokenz_userid;
            Restangular
            .one('users', userid)
            .get()
            .then(
                function (result) {
                    $scope.access = true;
                    $rootScope.user = result;
                    $scope.user = result;
                }
                );
            Restangular
                .one('users', user_elokenz_id)
                .all('social_accounts')
                .getList()
                .then(
                    function (result_sa) {
                        $scope.social_accounts = result_sa;

                        result_sa[0].active_repost_message = true;
                        localStorageService.set("social_accounts", result_sa);
                    }, function (response) {
                        $log.error("Couldn't get the social accounts : ", response);
                    }); // End get author
             localStorageService.set('user_elokenz_userid', userid);

        };

        $scope.update_twitter_follow = function() {
        //base_author.put(endpoint_author, $scope.author);
        Restangular.one('users', $scope.user.elokenz_userid).patch(
            {
                'twitter_follow_us': $scope.user.twitter_follow_us
            }
            );
        };

        // Menu
        $scope.tab = 1;
        $scope.isSet = function (tabId) {
            return $scope.tab === tabId;
        };
        $scope.setTab = function (tabId) {
            $scope.tab = tabId;
            switch(tabId){

              default:
                break;
            }
        };
        var tabSelected = ($location.search()).tab;  // we can redirect to selected tab directly
        if (tabSelected === 'invoices') {
          $scope.setTab(3);
        }

    });
