'use strict';

var module = angular.module('dashboardApp');
module.service('loginService', function(Restangular, $rootScope, localStorageService, $q, $log, $analytics, ENV, $location, spinnerService, growl, $route, repostService, Utils, onboardingService) {

  var self = this;
  var userId = localStorageService.get('user_elokenz_userid');
  // The base URL for all articles;
  var baseAuthor = Restangular.one('users',userId);

  // Service variables
  var user, userElokenzUserId, userEmail, userName, accessToken, refreshToken, postLogInRoute;
  var isInitiated = false;
  var isRefreshing = false;

  // Return the data when getting the service object
  this.getData = function(){
    return {
      'user': user,
    };
  };

  angular.isUndefinedOrNull = function(val) {
      return angular.isUndefined(val) || val === null;
  };


  /////////////////////////////////////////
  /////////////////////////////////////////

  this.saveUserData = function(userJsonData){
    user = userJsonData;
    userElokenzUserId = userJsonData.elokenz_userid;
    userEmail = userJsonData.email;
    userName = userJsonData.first_name + ' ' + userJsonData.last_name;
    $rootScope.user = userJsonData;
    localStorageService.set('user', userJsonData);
    localStorageService.set('user_elokenz_userid', userJsonData.elokenz_userid);
    localStorageService.set('user_email', userJsonData.email);
    localStorageService.set('first_name', userJsonData.first_name);
    localStorageService.set('last_name', userJsonData.last_name);
    $log.debug('We got the current user elokenz_id. Saved in localStorage');
    $rootScope.user_is_logged_in = true;
    $rootScope.user_elokenz_userid = userJsonData.elokenz_userid;
    $rootScope.user_email = userJsonData.email;
    try {
      spinnerService.show('loadUserData');
    } catch(err) {
      $log.error(err);
    }

    if (!angular.isUndefinedOrNull(userEmail)){
      self.identifyUserExternalAnalytics();
    }
  };

  this.identifyUserExternalAnalytics = function(){
    // Identify under Segment
    $log.debug("We identify the user on Analytics third-parties");

    //analytics.identify([userId], [traits], [options], [callback]);
    analytics.identify(userElokenzUserId);
    $analytics.setUserProperties({'email': userEmail, 'name': userName});

    // Google Analytics
    $analytics.settings.ga = {
      userId: userElokenzUserId
    };

    // GTM
    var dataLayer = window.dataLayer = window.dataLayer || [];
    dataLayer.push({
        'email': userEmail, 'username': userName
      });
  };

  this.checkWebuserSession = function(){
    // Check connection to www.elokenz.com :
    // needed when connecting a social account for 1st time
    var deferred = $q.defer();

    var sessionRoute = ENV.userUrl + '/is_connected/';
    Restangular
    .oneUrl('is_connected', sessionRoute)
    .withHttpConfig({withCredentials: true})
    .get()
    .then(
        function (result) {
            var isConnected = result.connected;
            $log.debug('Is connected ? ', isConnected);
            if (isConnected === true) {
                $log.debug('you are connected');
                deferred.resolve(true);
            } else {
                $log.warn('You are not connected to the main user database. You need to re-enter your login info');
                deferred.resolve(false);
            }
        }
    );
    return deferred.promise;
  };

  this.isLoggedIn = function(){
    return (localStorageService.get('user_elokenz_userid')!== undefined && localStorageService.get('user_elokenz_userid')!== null);
  };


  this.canConnect = function(){
    // Returns true if user has access to his personnal data via PAPI, false otherwise
    // The function also update 'user'
    var deferred = $q.defer();

    if (angular.isUndefinedOrNull(accessToken)) {
      deferred.resolve(false);
      return deferred.promise;
    }
    $log.debug('canConnect ', accessToken);
    var bearer = 'Bearer ' + accessToken;
    var headers = {Authorization: bearer};
    Restangular.setDefaultHeaders({Authorization: bearer}); // we need this to get authentified

    Restangular.one('users', 'me').get(headers=headers).then(function(result){
      self.saveUserData(result);
      deferred.resolve(true);
    }, function(response){
      $log.error('Couldn\'t get info about the current user : ', response);
      deferred.resolve(false);
    }); // End get author
    return deferred.promise;
  };

  this.getTokens = function(){
    accessToken = localStorageService.get('access_token');
    refreshToken = localStorageService.get('refresh_token');
  };

  this.saveToken = function(accessTokenInput, refreshTokenInput, signupFlag){
    // Use the tokens provided by the user, and save them in localstorage
    accessToken = accessTokenInput;
    refreshToken = refreshTokenInput;
    localStorageService.set('access_token', accessToken);
    localStorageService.set('refresh_token', refreshToken);
    $log.debug('We saved the access token in localStorage');

    if (signupFlag === 1){
      $analytics.eventTrack('Signed Up');
    }

  };

  this.login = function(accessTokenInput, refreshTokenInput, signupFlag){
    accessTokenInput = typeof accessTokenInput !== 'undefined' ? accessTokenInput : accessToken; // define default value
    refreshTokenInput = typeof refreshTokenInput !== 'undefined' ? refreshTokenInput : refreshToken; // define default value
    signupFlag = typeof signupFlag !== 'undefined' ? signupFlag : false; // define default value

    var deferred = $q.defer();
    self.saveToken(accessTokenInput, refreshTokenInput, signupFlag);
    self.canConnect().then(function(result){
      // result is true if we could get userData from Papi
      var isPAPIConnected = result;
      if (isPAPIConnected){
        $log.debug('Token work and have been saved');
        self.checkWebuserSession().then(function(result){
          var isWebuserConnected = result;
          if (isWebuserConnected){
            if (postLogInRoute && self.isLoggedIn()) {
              $location.path(postLogInRoute).replace();
              postLogInRoute = null;
              $route.reload();
            } else {
              $location.path('/', null);
            }
            deferred.resolve(true);
            // Sending the event for some directives (like onboardingTour)
            var $scope = angular.element(document).scope();
            var event = 'login';
            var args = [];
            $scope.$broadcast(event, args);
          }
        }, function(response){
          $log.error('Couldn\'t get the session from webuser : ', response);
          deferred.resolve(false);
        }); // End get author
      }
      deferred.resolve(false);
      $log.error('Could not get access to user information.');
    }, function(response){
      $log.error('Couldn\'t connect with the given credentials : ', response);
      deferred.resolve(false);
    }); // End get author
    return deferred.promise;
  };

  this.logoutCleanup = function(){
    // Logout from our own services
    try {
        Utils.reset();
    } catch(err) {
        $log.error(err.message);
    }
    try {
        repostService.reset();
    } catch(err) {
        $log.error(err.message);
    }
    try {
        onboardingService.reset();
    } catch(err) {
        $log.error(err.message);
    }
    // Logout from Analytics 3-rd parties
    try {
        drift.reset();
    } catch(err) {
        $log.error(err.message);
    }
    // Logout Intercom
    try {
        Intercom('shutdown');
    } catch (err) {
        $log.error(err.message);
    }
    // Logout Segment
    try {
        analytics.reset();
    } catch(err) {
        $log.error(err.message);
    }

    try {
      spinnerService.hide('loadUserData');
    } catch(err) {
      $log.error(err);
    }

    // Clear all rootscope except logout function ... might need a more careful attention since we might be deleting other functions
    for (var prop in $rootScope) {
        if (prop.substring(0,1) !== '$' && prop !== 'logout') {
            $log.debug("Deleting from rootscope : ", prop);
            delete $rootScope[prop];
        }
    }
    // Removed localStorageInfo
    localStorageService.clearAll();

    $log.debug('User is logged out. Let\'s redirect');
    $location.path('/');
  };

  this.logout = function(){

    var revokingRoute = ENV.userUrl + '/o/revoke_token/';
    var auth = Restangular.oneUrl('revoking_route', revokingRoute);
    var revokingTokenPost = 'token=' + accessToken + '&client_id=u=6f-_XwY8VJSp_Td9RoiAwpaHUIuL8fuZtbUs@A&token_type_hint=access_token';
    auth.customPOST(revokingTokenPost, '', undefined, {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}).then(function (data) {

      $log.debug('We are loging out - Token has been revoked !');
      self.logoutCleanup();

      // Sending the event for some directives (like onboardingTour)
      var $scope = angular.element(document).scope();
      var event = 'logout';
      var args = [];
      $scope.$broadcast(event, args);

    }, function(response){
      $log.error('Couldn\'t log the user properly - we still try to log it out. Response received : ', response);
      self.logoutCleanup();
    }); // End get author);
  };

  this.tryRefreshingToken = function(){
    var q = $q.defer();
    if ($rootScope.user_is_logged_in === true){
      postLogInRoute = $location.path();
      if (!isRefreshing) {
        growl.warning('It seems your connection was lost... We are trying to reconnect you! The page will refresh automatically when it will be done');
        isRefreshing = true;
        $log.warn('We are refreshing the token!!!!');
        self.init();
        if (refreshToken !== undefined && refreshToken !== '') {
          var refreshingRoute = ENV.userUrl + '/o/token/';
          var auth = Restangular.oneUrl('auth_route', refreshingRoute);
          var refreshingTokenPost = 'grant_type=refresh_token&refresh_token=' + refreshToken + '&client_id=u=6f-_XwY8VJSp_Td9RoiAwpaHUIuL8fuZtbUs@A';
          auth.customPOST(refreshingTokenPost, '', undefined, {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}).then(
              function (data) {
                  $log.debug('Sent refresh token via POST. Now we refresh all the current login information');
                  $log.debug(data);
                  self.saveToken(data.access_token, data.refresh_token, false)
                  self.login();
                  isRefreshing = false;
                  q.resolve();
              }, function (response) {
                  $log.error('Could not refresh the token\n\nResponse : ', response);
                  self.logout();
                  isRefreshing = false;
                  q.reject(response);
              }
          );
        } else {
          // Otherwise we redirect to login
          $log.debug('You are not connected');
          if ($location.path() === '/login') {
              //  allow processing to continue so my login controller can show an error message
              q.resolve();
          } else {
              $location.path('/login');
              q.resolve();
              // return false;
          }
        }
      } else{
        q.resolve();
      }

    } else { // user is not connected, no need to refresh token
      q.resolve();
    }
    return q.promise;
  };

  this.init = function(){
    if (!isInitiated) {
      self.getTokens();
      isInitiated = true;
    }
  };



});
